import Home from './Views/Home.vue';
import About from './Views/About.vue';
import Brands from "./Views/Brands";
// import Products from "./Views/Products";
import Technology from "./Views/Technology";
import JoinTheTeam from "./Views/JoinTheTeam";
import Position from "./Views/Position";
import Contact from "./Views/Contact";

import SimplyMoney from "./Views/Brands/SimplyMoney";
import BelieveLoans from "./Views/Brands/BelieveLoans";
import BelieveFinance from "./Views/Brands/BelieveFinance";
import BelieveSpecialistFinance from "./Views/Brands/BelieveSpecialistFinance";
import BelieveProtect from "./Views/Brands/BelieveProtect";

import PageNotFound from "./Views/404";
import Policy from "./Views/Policy";

export const routes = [
    {
        name: "Home",
        path: "/",
        component: Home
    },
    {
        name: "About",
        path: `/about`,
        component: About
    },
    {
        name: "Brands",
        path: `/brands`,
        component: Brands
    },
    {
        name: "Brands-SimplyMoney",
        path: `/brands/simply-money`,
        component: SimplyMoney
    },
    {
        name: "Brands-BelieveLoans",
        path: `/brands/believe-loans`,
        component: BelieveLoans
    },
    {
        name: "Brands-BelieveFinance",
        path: `/brands/believe-finance`,
        component: BelieveFinance
    },
    {
        name: "Brands-BelieveSpecialistFinance",
        path: `/brands/believe-specialist-finance`,
        component: BelieveSpecialistFinance
    },
    {
        name: "Brands-BelieveProtect",
        path: `/brands/believe-protect`,
        component: BelieveProtect
    },
    // {
    //     name: "Products",
    //     path: `/products`,
    //     component: Products
    // },
    {
        name: "Technology",
        path: `/technology`,
        component: Technology
    },
    {
        name: "Join The Team",
        path: `/join-the-team`,
        component: JoinTheTeam,
    },
    {
        name: "Job Positions",
        path: `/join-the-team/:position/:role`,
        component: Position
    },
    {
        name: "Contact",
        path: `/contact`,
        component: Contact
    },
    {
        name: "Privacy",
        path: `/privacy`,
        component: Policy
    },
    {
        name: "Terms",
        path: `/terms`,
        component: Policy
    },
    {
        name: "Cookies",
        path: `/cookies`,
        component: Policy
    },
    {
        name: "404",
        path: `*`,
        component: PageNotFound
    }
]