<template>
  <div class="bmg">
    <Header />
    <Hero background="hero-technology.png" col="col-12 col-lg-5">
      <h1 class="fw-bolder">Technology</h1>
      <div slot="right" class="col-12 col-lg-7">
        <img src="@/assets/elements/technology/technology-header.png" class="img-fluid" />
      </div>
    </Hero>
    <div class="container-fluid">
      <div class="container py-5">
        <div class="row align-items-center">
          <div class="col-12 col-md-6 order-0 order-md-1">
            <h2 class="fw-bolder">Decisioning &amp; right advice</h2>
            <p>We connect with multiple lenders directly via API, sourcing and making decisions in real-time to provide real-rate offers for consumers both online and offline.</p>
            <p>Our flagship personal loan platform, Simply Money, gives real-rate offers unique to each consumer, with connectivity and access through to our regulated first and second charge mortgage products.</p>
          </div>
          <div class="col-12 col-md-6 d-flex align-items-center">
            <img src="@/assets/elements/technology/decisioning-right-advice.png" class="img-fluid d-flex mt-3 mx-auto pe-5" alt="Decisioning and right advice"/>
            <div class="dot d-none d-lg-block"/>
          </div>
        </div>
      </div>
    </div>
    <Hero background="hero-technology-blank.png" col="col-12 col-lg-5">
      <h1 class="fw-bolder">Customer friendly technology</h1>
      <p>Our customers are at the heart of everything we do – a fact supported by our thousands of five-star customer reviews across all brands.</p>
      <p>By empowering consumers to interact with us on multiple channels – including customer portals, secure apps and bots – we give them choice and control, along with the reassurance of support via our advisors and agents.</p>
      <div slot="right" class="col-12 col-lg-7">
        <img src="@/assets/elements/technology/customer.png" class="img-fluid" style="margin-bottom:-3rem"/>
      </div>
    </Hero>
    <div class="container-fluid bg-tertiary">
      <div class="container py-5">
        <div class="row align-items-center">
          <div class="col-12 col-md-6 order-0 order-md-1">
            <h2 class="fw-bolder">Connectivity</h2>
            <p>Partners and white label affinity solutions can connect directly to each vertical or via a central funnel point, giving them access to our full suite of products. Two-way API integrations provide real-time decisions that can be housed on the partner web estates, i-frames and embedded application forms, providing a wider range of connectivity solutions for all scenarios.</p>
          </div>
          <div class="col-12 col-md-6 d-flex align-items-center">
            <img src="@/assets/elements/technology/connectivity.png" class="img-fluid d-flex mt-3 mx-auto pe-5" alt="Connectivity"/>
            <div class="dot d-none d-lg-block"/>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid">
      <div class="container py-5">
        <div class="row align-items-center">
          <div class="col-12 col-md-6">
            <h2 class="fw-bolder">Reporting</h2>
            <p>Our proprietary software powers our Group across all our verticals, providing efficiency, scale and seamless interconnection across products.</p>
            <p>We're driven by data, with tabular insights and trigger-based execution that we can adapt and improve in real-time. Our ability to see our customers holistically provides a clear pathway to lifetime value, and builds a true customer lifecycle within the Group.</p>
            <div class="row">
              <div class="col-5 col-sm-4 col-md-3">
                <img src="@/assets/elements/technology/clicktech.png" class="img-fluid" alt="ClickTech"/>
              </div>
              <div class="col-5 col-sm-4 col-md-3">
                <img src="@/assets/elements/technology/bx.png" class="img-fluid" alt="BX"/>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6 d-flex align-items-center">
            <img src="@/assets/elements/technology/reporting.png" class="img-fluid d-flex mt-3 mx-auto" alt="Connectivity"/>
            <div class="dot d-none d-lg-block"/>
          </div>
        </div>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
import Header from '../Sections/Scaffold/Header.vue'
import Footer from '../Sections/Scaffold/Footer.vue'
import Hero from "../Sections/Hero.vue";

export default {
  name: 'Technology',
  components: {
    Hero,
    Header,
    Footer
  }
}
</script>

<style scoped lang="scss">
</style>