
<template>
  <div v-if="brand && brand.businessUnitId.length" :class="`container-fluid ${noBackground === false ? 'bg-tertiary' : ''}`">
    <div class="container py-3 py-sm-5">
        <div class="trustpilot-widget"
             ref="trustpilot"
             data-locale="en-GB"
             data-template-id="53aa8912dec7e10d38f59f36"
             :data-businessunit-id="brand.businessUnitId"
             data-style-height="140px"
             data-style-width="90%"
             data-theme="light" data-stars="4,5">
          <a :href="`https://uk.trustpilot.com/review/${brand.domain}`" target="_blank" rel="noopener">Trustpilot</a>
        </div>
    </div>
  </div>
</template>

<script>
const brands = [
  {
    id: 'simply-money',
    domain: 'simplymoney.com',
    businessUnitId: '5c8ad21c17f74e0001040443'
  },
  {
    id: 'believe-loans',
    domain: 'believeloans.co.uk',
    businessUnitId: '59a6e7ff0000ff0005aa092e'
  },
  {
    id: 'believe-finance',
    domain: 'believefinance.co.uk',
    businessUnitId: '5f6b404265e7620001308f48'
  },
  {
    id: 'believe-specialist-finance',
    domain: 'believespecialistfinance.co.uk',
    businessUnitId: ''
  },
  {
    id: 'believe-protection',
    domain: 'believeprotect.co.uk',
    businessUnitId: ''
  }
]

export default {
  props: {
    id: {
      type: String
    },
    noBackground: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      brands
    }
  },
  mounted(){
    const body = document.getElementsByTagName("body")[0];
    if (window.TrustPilotScript) {
      window.TrustPilotScript.remove();
    }
    window.TrustPilotScript = document.createElement("script");
    window.TrustPilotScript.addEventListener("load", this.trustPilotLoaded);
    window.TrustPilotScript.async = true;
    window.TrustPilotScript.src = "//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js";
    body.appendChild(window.TrustPilotScript);
  },
  computed: {
    brand() {
      return this.brands.find(x => x.id === this.id);
    }
  },
  methods: {
    trustPilotLoaded() {
      if (this.brand && this.brand.businessUnitId.length) {
        window.Trustpilot.loadFromElement(this.$refs.trustpilot);
      }
    }
  },
  watch: {
    id: function() {
      if (window.Trustpilot && this.$refs.trustpilot) {
        this.trustPilotLoaded();
      }
    }
  }
}
</script>

<style>
.trustpilot-widget{
  width: 100%;
}

@media (max-width: 426px){
  .trustpilot-widget iframe {
    height: 275px !important;
    /*width: 100% !important;*/
    margin: 0 auto;
  }
}
</style>