<template>
  <div>
    <div :class="`container-fluid hero ${backgroundMobile ? 'd-none d-lg-flex' : ''}`"
         :style="`background-image: url(${require(`@/assets/backgrounds/${background}`)});${backgroundStyle}`">
      <div :class="`container ${containerStyle}`">
        <div :class="`row ${rowStyle}`">
          <div :class="col">
            <slot></slot>
          </div>
          <slot name="right"></slot>
        </div>
      </div>
    </div>
    <div
        v-if="backgroundMobile"
        :class="`container-fluid hero ${backgroundMobile ? 'd-block d-lg-none' : ''}`"
        :style="`background-image: url(${require(`@/assets/backgrounds/${backgroundMobile}`)});${backgroundMobileStyle}`">
      <div class="container py-5">
        <div class="row align-items-center">
          <div :class="col">
            <slot></slot>
          </div>
          <slot name="right"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Hero",
  props: {
    containerStyle: {
      type: String,
      default: 'py-5'
    },
    rowStyle: {
      type: String,
      default: 'align-items-center'
    },
    background: {
      type: String,
      default: 'hero-home.png'
    },
    backgroundStyle: {
      type: String,
      default: ''
    },
    backgroundMobile: {
      type: String,
      default: null
    },
    backgroundMobileStyle: {
      type: String,
      default: ''
    },
    col: {
      type: String,
      default: ''
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/scss/index.scss';
.hero {
  width:100%;
  min-height: 50vh;
  background-size: cover;
  background-position: 50% top;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color:$white;
}
</style>
