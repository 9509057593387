<template>
  <div class="bmg">

    <Header />

    <Hero col="col-12 col-lg-5" background="hero-brands-believe-loans.png">
      <img src="@/assets/elements/brands/believe-loans/logo-light.svg" class="img-fluid" alt="Believe Loans Logo" />
      <h2 class="fw-bolder text-white mt-5">Quick &amp; easy loans</h2>
      <div slot="right" class="col-12 col-lg-7">
        <img src="@/assets/elements/brands/believe-loans/hero.png" class="img-fluid" style="margin-bottom:-3rem;" />
      </div>
    </Hero>

    <div class="container py-3">
      <h2 class="text-center">At a glance</h2>
      <div class="row p-0 m-0 align-items-center">
        <div class="col-12 col-md-4 p-5">
          <StatCircle
              bg-left="#202548"
              bg-right="#70B8D1"
              title="350+"
              subtitle="Units per month" />
        </div>
        <div class="col-12 col-md-4 p-5">
          <StatCircle
              bg-left="#202548"
              bg-right="#70B8D1"
              title="2"
              subtitle="Sites" />
        </div>
        <div class="col-12 col-md-4 p-5">
          <StatCircle
              bg-left="#202548"
              bg-right="#70B8D1"
              title="£13M+"
              subtitle="Monthly Lending" />
        </div>
      </div>
    </div>

    <div class="container-fluid bg-tertiary">
      <div class="container py-5">
        <div class="row align-items-center">
          <div class="col-12 col-md-6">
            <img src="@/assets/elements/brands/believe-loans/logo.svg" class="img-fluid" alt="Believe Loans Logo" />
            <h5 class="fw-bold">About Believe Loans</h5>
            <p>A leading award-winning broker in the UK 2nd charge mortgage landscape,
              giving access to homeowner exclusive products across the credit spectrum.
            </p>
            <p>
              Direct to consumer marketing channels and brand partner relationships make
              Believe Loans one of the most respected Secured Loan Brokers in the UK.</p>
            <p>
              Hundreds of products, loads of options, our technology cuts through the
              noise and our experts advise on the most suitable options.
            </p>
          </div>
          <div class="col-12 col-md-6 d-flex align-items-center d-none d-md-block">
            <img src="@/assets/elements/brands/believe-loans/about.png" class="img-fluid d-flex mt-3 mx-auto" alt="About Believe Loans"/>
          </div>
        </div>
      </div>
    </div>

    <TrustPilot id="believe-loans" />

    <div class="container-fluid what-we-offer">
      <div class="container py-3 pb-5">
        <div class="row">
          <div class="col text-center">
            <h2 class="fw-bold py-3 ">What we offer</h2>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-4 px-5 pb-md-0 d-flex flex-column">
            <img src="@/assets/elements/offer/offer-00.svg" class="img-fluid" alt="We believe in freedom"/>
            <h5 class="fw-bold pt-2">
              Online Loan Comparison
            </h5>
            <p>
              Quick and simple comparison of different loan products helps customers make decisions easily.
            </p>
          </div>
          <div class="col-12 col-md-4 px-5 pb-md-0 d-flex flex-column">
            <img src="@/assets/elements/offer/offer-06.svg" class="img-fluid" alt="We believe in good health"/>
            <h5 class="fw-bold pt-2">
              Partner Decline Flow
            </h5>
            <p>
              Partners’ decline customers are able to access a carefully controlled panel of products for
              underserved homeowners.
            </p>
          </div>
          <div class="col-12 col-md-4 px-5 pb-md-0 d-flex flex-column">
            <img src="@/assets/elements/offer/offer-04.svg" class="img-fluid" alt="We believe in fun"/>
            <h5 class="fw-bold pt-2">
              Offline Assisted Support
            </h5>
            <p>
              Where appropriate for the product, each client is supported by a fully qualified, friendly
              and experienced advisor who will ensure the best possible outcome is achieved.
            </p>
          </div>
        </div>
      </div>
    </div>

    <Hero col="col-12 col-lg-6" background="info-brands-believe-loans.png">
      <img src="@/assets/elements/brands/believe-loans/info.png" class="img-fluid d-flex mt-3 pb-4 mx-auto" alt="Information about Believe Loans"/>
      <div slot="right" class="col-12 col-lg-6">
        <p>
          We've developed our own advanced technology platform to help get quotes
          quickly and securely however the customer wants to interact.
        </p>
        <ul class="list-unstyled">
          <li :key="`item-${index}`" v-for="(item, index) in items">
            <img :src="`${require(`@/assets/elements/tick.svg`)}`"
                 alt="Bullet Point"
                 :class="`py-2 me-3`"> {{ item }}</li>
        </ul>
        <p class="fw-bold">For more information or to apply then...</p>
        <a href="https://www.believeloans.com"
           target="_blank"
           class="btn btn-bmg icon-right d-flex d-md-inline-block">Visit Believe Loans <i class="bi bi-caret-right-fill"></i></a>
      </div>
    </Hero>

    <div class="container-fluid">
      <div class="container py-5">
        <div class="row">
          <div class="col text-center">
            <router-link to="/brands" class="btn btn-bmg icon-right d-flex d-md-inline-block">View our other brands <i class="bi bi-caret-right-fill"></i></router-link>
          </div>
        </div>
      </div>
    </div>

    <Footer />

  </div>
</template>

<script>
import Header from '../../Sections/Scaffold/Header.vue'
import Footer from '../../Sections/Scaffold/Footer.vue'
import StatCircle from "../../components/StatCircle";
import TrustPilot from "../../Sections/TrustPilot";
import Hero from "../../Sections/Hero.vue";

export default {
  name: 'Brands',
  components: {
    Hero,
    TrustPilot,
    StatCircle,
    Header,
    Footer
  },
  data() {
    return {
      items: [
          "Loans from £10,000 to £500,000",
          "All credit profiles considered",
          "As a leading Credit Broker and not a lender we’ve more offers and options",
          "No upfront or hidden fees",
          "Fast secure online process",
          "Award Winning Broker"
      ]
    }
  }
}
</script>
