import 'whatwg-fetch'
import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import {routes} from './routes'
import "./assets/scss/general.scss"
import 'bootstrap-icons/font/bootstrap-icons.css'

Vue.config.productionTip = false;
Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) return savedPosition;
    if (to.hash) {
      return { selector: to.hash };
    }
    return { x: 0, y: 0 };
  },
  routes
})

new Vue({
  render: h => h(App),
  router
}).$mount('#app')
