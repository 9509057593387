<template>
  <div class="bmg-recruitment">

    <Header />

    <Hero background="hero-recruitment.png" background-style="min-height: 80vh;">
      <div class="bmg-recruiment__hero">
        <h1 class="fw-bolder">Join the Team</h1>
        <p class="mb-1">Ready for a fresh challenge?</p>
        <p class="mb-1">Do you want to have fun and enjoy your work?</p>
        <p class="mb-1">Then join us at Believe Money Group.</p>
      </div>
    </Hero>

    <JoinTheTeamPositions/>

    <div class="container-fluid">
      <div class="container py-3 py-md-5">
        <h2 class="fw-bold mt-0">Apply to become a believer...</h2>
        <apply-for-position/>
      </div>
    </div>

    <Hero background="hero-blank.png" background-style="min-height: 30vh;background-position: right center;">
      <div class="d-flex align-items-center gap-5">
        <h2 class="text-center">Alternatively, send your CV to: <a class="bmg-recruitment__cv-link btn-link text-white text-decoration-none" href="mailto:recruitment@believemoney.com">recruitment@<br class="d-block d-lg-none"/>believemoney.com</a></h2>
        <img src="@/assets/elements/paper-plane.svg" class="img-fluid h-75 d-none d-md-block" alt="Send Email" />
      </div>
    </Hero>

    <JoinTheTeamInYou/>

    <Hero background="hero-benefits.png"
          background-mobile="hero-blank.png"
          background-style="min-height:80vh;"
          col="col-12 offset-0 col-lg-4 offset-lg-8 d-flex flex-column justify-content-center justify-items-center">
        <h1 class="text-white fw-bolder display-6">Believer Benefits...</h1>
        <h5 class="text-uppercase fw-bold">Employee of the month</h5>
      <img src="@/assets/elements/5-stars.svg" class="img-fluid" style="width:235px;"/>
        <h1 class="fw-bolder text-primary display-2 m-0">&pound;500</h1>
        <h5 class="text-uppercase mb-0 fw-bold">To spend on</h5>
        <h1 class="m-0 fw-bold text-uppercase display-6">Anything!</h1>
        <h5 class="text-uppercase mt-0 fw-bold">You choose, we buy!</h5>
        <img src="@/assets/elements/believer-benefits.png"
             class="img-fluid rounded mt-5 d-block d-lg-none"
             alt="Employee of the Quarter" />
    </Hero>

<!--    <div class="container-fluid join-the-team__benefits pt-0 pb-0 pt-md-5 pb-md-5"-->
<!--         :style="`background-image: url(${require(`@/assets/backgrounds/hero-benefits.png`)});`">-->
<!--      <div class="container py-5">-->
<!--        <div class="row align-items-center">-->
<!--          <div class="col-12 col-md-6 text-uppercase text-white join-the-team__benefits__info">-->
<!--            <h3 class="text-primary">Believer Benefits...</h3>-->
<!--            <small>Employee of the quarter:</small>-->
<!--            <h1 class="fw-bold text-primary">&pound;1K</h1>-->
<!--            <span>To spend on</span>-->
<!--            <h4 class="m-0 fw-bold">Anything!</h4>-->
<!--            <span>You choose, we buy!</span>-->
<!--            <img src="@/assets/elements/believer-benefits.png"-->
<!--                 class="img-fluid rounded mt-5 d-block d-md-none"-->
<!--                 alt="Employee of the Quarter" />-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->

    <div class="container-fluid join-the-team__fun pt-0 pb-0 pt-md-5 pb-md-5">
      <div class="container py-5">
        <div class="row align-items-center">
          <div class="col-12 col-md-5">
            <img src="@/assets/elements/benefits/fun.svg" class="img-fluid" alt="We believe in fun"/>
            <p>We are a friendly bunch and like to have fun. Social committees make sure there's always stuff in the diary like quarterly events and team building days.</p>
          </div>
          <div class="col-12 col-md-6 offset-md-1">
            <img src="@/assets/elements/believer-fun.png"
                 class="img-fluid rounded"
                 alt="Believers in fun" />
          </div>
        </div>
      </div>
    </div>

    <Footer />

  </div>
</template>

<script>
import Header from '../Sections/Scaffold/Header.vue'
import Footer from '../Sections/Scaffold/Footer.vue'
import JoinTheTeamPositions from "../Sections/JoinTheTeam/Positions";
import Hero from "../Sections/Hero.vue";
import JoinTheTeamInYou from "../Sections/JoinTheTeam/InYou.vue";
import ApplyForPosition from "../Sections/JoinTheTeam/ApplyForPosition.vue";

export default {
  name: 'JoinTheTeam',
  components: {
    ApplyForPosition,
    JoinTheTeamInYou,
    Hero,
    JoinTheTeamPositions,
    Header,
    Footer
  }
}
</script>

<style lang="scss">
.bmg-recruiment__hero > * {
  text-shadow: 0px 0px 10px rgba(0, 0, 0, 1);
}

/* 767px */
@media (max-width: 767px) {
  .bmg-recruitment .container-fluid.hero {
    background-position: -100px top;
  }
}

/* 991px */
@media (max-width: 991px) {
  .bmg-recruitment .container-fluid.hero {
    background-position: -250px top;
  }
}

.join-the-team__benefits {
  background-size:cover;
  background-position: center center;
}

.bmg-recruitment__cv-link,
.join-the-team__benefits__info {
  color:white !important;
}
</style>