<template>
  <div class="container-fluid we-believe">
    <div class="container py-5">
      <div class="row">
        <div class="col-12 col-md-4 px-5 pb-md-0">
          <img src="@/assets/elements/we-believe-offer.svg" class="img-fluid" alt="Offer"/>
          <h5 class="pt-5 fw-bold">
            We Believe in the right financial product
          </h5>
          <p>
            We develop processes that serve our customers by providing the most
            appropriate products, provided through smart technology, supported by
            professional staff, delivered through
            efficient processes.
          </p>
        </div>
        <div class="col-12 col-md-4 px-5 pb-md-0">
          <img src="@/assets/elements/we-believe-privacy.svg" class="img-fluid" alt="Privacy"/>
          <h5 class="pt-5 fw-bold">
            We Believe in doing things properly
          </h5>
          <p>
            Our full suite of financial products complies to FCA guidelines, our governance and
            controls ensure we operate professionally  to the highest standards of TCF for every
            customer we interact with.
          </p>
        </div>
        <div class="col-12 col-md-4 px-5">
          <img src="@/assets/elements/we-believe-feedback.svg" class="img-fluid" alt="Feedback"/>
          <h5 class="pt-5 fw-bold">
            We Believe that feedback matters
          </h5>
          <p>
            Our customers are at heart of everything we do, supported by our 1000’s of 5 star
            customer reviews across all brands.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AboutBelieve",
}
</script>

<style scoped lang="scss">
@import '@/assets/scss/index.scss';
.about-believe {
  h2 {
    color: $bmg;
  }
}
</style>