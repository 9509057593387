<template>
  <div class="container-fluid brands">
    <div class="container py-5">
      <div class="row">
        <div class="col-12 col-lg-6 offset-lg-3 text-center">
          <h4 class="fw-bolder">Market leading brands using technology to maximise conversions</h4>
        </div>
      </div>
      <img src="@/assets/elements/brands-leading-tech.jpg" class="img-fluid m-auto mb-4" alt="Leading Brands"/>
      <div class="row align-items-center">
        <div class="col-8 offset-2 col-md-4 offset-md-0 pb-4">
          <img src="@/assets/elements/brands/simply-money/logo.svg" class="img-fluid m-auto" alt="Simply Money"/>
        </div>
        <div class="col-8 offset-2 col-md-4 offset-md-0 pb-4">
          <img src="@/assets/elements/brands/believe-finance/logo.svg" class="img-fluid m-auto" alt="Believe Finance"/>
        </div>
        <div class="col-8 offset-2 col-md-4 offset-md-0 pb-4">
          <img src="@/assets/elements/brands/believe-loans/logo.svg" class="img-fluid m-auto" alt="Believe Loans"/>
        </div>
        <div class="col-8 offset-2 col-md-4 offset-md-2 pb-4">
          <img src="@/assets/elements/brands/believe-protect/logo.svg" class="img-fluid m-auto" alt="Believe Protect"/>
        </div>
        <div class="col-8 offset-2 col-md-4 offset-md-0 pb-4">
          <img src="@/assets/elements/brands/believe-specialist-finance/logo.svg" class="img-fluid m-auto" alt="Believe Specialist Finance"/>
        </div>
        <div class="col-12 text-center mt-5">
          <router-link to="/brands" class="btn btn-bmg icon-right d-flex d-lg-inline-block">Our Brands <i class="bi bi-caret-right-fill"></i></router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Brands"
}
</script>

<style scoped>

</style>