<template>
  <Hero
      background="hero-believers.png"
      background-style="background-position: 45% top;"
      background-mobile="hero-blank.png"
      background-mobile-style="background-position: center top;"
      col="col-12 col-md-12 col-lg-5">
    <h2 class="fw-bold pt-5">Tech underpinned by talent...</h2>
    <h3 class="fw-bold">Meet the believers</h3>
    <p>Our UK-based team is our pride and joy. They’re the people
      who work with our customers, supporting them all the way as
      they go on their journey with us. Each team member is friendly,
      knowledgeable and committed to the way we do business. In
      short, every one of them is a Believer.</p>
    <p>
      We attract the best people because we’re a brilliant place to
      work. We offer everything from full training and great career
      progression to outstanding rewards. By
      investing in our Believers, we make sure our customers get the
      very best service every time –and what’s great for our
      customers is great for our business too.
    </p>
    <router-link to="/join-the-team" class="btn btn-bmg icon-right d-flex d-lg-inline-block mb-5">Become a Believer <i class="bi bi-caret-right-fill"></i></router-link>
    <img src="@/assets/elements/a-believer.png" class="img-fluid m-auto d-block d-lg-none mt-4 mb-4" alt="I'm a believer" />
    <img src="@/assets/elements/believer-staff.jpg" class="img-fluid m-auto d-block d-lg-none rounded " alt="Believers" />
  </Hero>
</template>

<script>
import Hero from "./Hero.vue";

export default {
  name: "Believers",
  components: {Hero}
}
</script>

<style scoped lang="scss">
</style>
