<template>
  <div class="container-fluid bg-tertiary believers-positions">
    <div class="container pt-3">
      <div class="row">
        <div class="col">
          <h2 class="py-3 fw-bold">Become a believer...</h2>
          <h3 v-if="subtitle">{{ subtitle }}</h3>
          <h3 v-else>Current Positions</h3>
          <p v-if="!havePositions">We currently have no open positions at the moment. Please check back soon!</p>
        </div>
      </div>
      <div v-if="havePositions">
        <div class="row form align-items-center">
          <div class="col-12 col-sm-6 col-md-6 col-lg mt-2">
            <div class="form-floating">
              <select class="form-control" v-model="selectedArea" id="area">
                <option value="">All</option>
                <option v-for="area in areas" :key="area.title" :value="area.title">
                  {{ area.title }}
                </option>
              </select>
              <label for="area">View by job type</label>
            </div>
          </div>
          <div class="col-12 col-sm-6 col-md-6 col-lg mt-2">
            <div class="form-floating">
              <select class="form-control" v-model="selectedDepartment" id="department">
                <option value="">All</option>
                <option v-for="area in departments" :key="area" :value="area">
                  {{ area }}
                </option>
              </select>
              <label for="department">View by department</label>
            </div>
          </div>
          <div class="col-12 col-sm-6 col-md-6 col-lg mt-2">
            <div class="form-floating">
              <select class="form-control" v-model="selectedLocation" id="location">
                <option value="">All</option>
                <option v-for="(location) in locations" :key="location" :value="location">
                  {{ location }}
                </option>
              </select>
              <label for="location">View by location</label>
            </div>
          </div>
          <div class="col-12 col-sm-6 col-md-6 col-lg mt-2">
            <button
                @click="() => { selectedArea = ''; selectedDepartment = ''; selectedLocation = ''; }"
                class="btn btn-bmg icon-right w-100">View All</button>
          </div>
        </div>
        <div class="believers-positions__results">
          <h4>Results</h4>
          <p v-if="positions.length === 0">There are no positions that match your selection. Please change the options above to view other positions.</p>
          <div class="row row-cols-1 row-cols-md-2">
            <div v-for="(role, roleIndex) in positions" :key="`role-${roleIndex}`" class="col mb-4">
              <div class="card">
                <div class="card-body my-2">
                  <h3 class="m-0 fw-bold">{{ role.area.title }}</h3>
                  <h4 class="m-0">{{ role.title }}</h4>
                  <p>{{ role.description }}</p>
                  <div class="d-flex justify-content-center align-items-center mb-4">
                    <img src="@/assets/elements/pin.svg" :alt="role.location" class="img-fluid"/>
                    <div class="h3 px-3">{{ role.locations.join(" / ") }}</div>
                  </div>
                  <router-link :to="`/join-the-team/${role.area.slug}/${role.slug}`"
                               class="btn btn-bmg icon-right d-flex"
                  >Find out more <i class="bi bi-caret-right-fill"/></router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <img src="../../assets/elements/believers.svg" alt="Believers" style="width:50%;" class="img-fluid d-block ms-auto me-auto"/>
      </div>
    </div>
  </div>
</template>

<script>
import {positions,locations} from "./job-positions.js";

export default {
  name: "JoinTheTeamPositions",
  props: {
    subtitle: {
      type: String
    },
    department: {
      type: String
    }
  },
  data() {
    return {
      selectedArea: "",
      selectedDepartment: "",
      selectedLocation: ""
    }
  },
  computed: {
    havePositions() {
      return positions.length > 0;
    },
    positions() {
      let p = positions
          .filter(p => this.selectedArea === "" || p.title === this.selectedArea)
          .sort((a, b) => a.title.localeCompare(b.title))
          .map(p => {
          return p.roles
              .filter(l => this.selectedLocation === "" || l.locations.includes(this.selectedLocation))
              .filter(d => this.selectedDepartment === "" || d.more.department === this.selectedDepartment)
              .sort((a, b) => a.title.localeCompare(b.title))
              .map(x => {
            return {
              ...x,
              area: {
                title: p.title,
                slug: p.slug
              }
            }
          })
        }).flat();
      console.log(p);
      return p;
    },
    areas() {
      return positions.sort((a, b) => a.title.localeCompare(b.title));
    },
    departments() {
      return positions.map(x => x.roles.map(r => r.more.department)).flat();
    },
    locations() {
      return Object.keys(locations).map(x => locations[x]).sort((a, b) => a.localeCompare(b));
    }
  }
}
</script>


<style scoped lang="scss">
</style>
