<template>
  <div class="bmg">

    <Header />

    <Hero col="col-12 col-lg-5" background="hero-believers-position.png" background-style="min-height: 60vh;">
      <h1 class="fw-bolder">{{role.title }}</h1>
    </Hero>

    <div class="container-fluid bg-tertiary">
      <div class="container py-3 py-md-5">
        <div class="row">
          <div class="col-12 col-md-6 order-1 order-md-0">
            <div class="d-flex bg-white px-3 pb-3
            flex-column flex-lg-row
            justify-content-between
            align-items-center align-content-lg-end">
              <div>
                <h4>Department</h4>
                <p>{{ role.more.department }}</p>
                <h4>Responsible To</h4>
                <p>{{ role.more.responsibleTo }}</p>
                <h4>Hours</h4>
                <p class="p-0 m-0" v-for="(hour, hourIndex) in role.more.hours" :key="`hour-${hourIndex}`">{{ hour }}</p>
              </div>
              <div class="d-flex flex-column justify-content-center align-items-center p-4 p-lg-0">
                <img src="@/assets/elements/pin.svg" :alt="role.locations.join(',')" class="img-fluid" />
                <div class="h5 px-3">{{ role.locations.join(' / ') }}</div>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6">
            <h2 class="fw-bold">Become a believer...</h2>
            <p v-for="(description, descriptionIndex) in role.more.description" :key="`description-${descriptionIndex}`">{{ description }}</p>
          </div>
        </div>

      </div>
    </div>

    <div class="container-fluid position-points">
      <div class="container py-3 py-md-5">
        <div class="row">
          <div class="col">

            <div v-if="role.more.skills">
              <h3>Core Skills:</h3>
              <ul>
                <li v-for="(skill, skillIndex) in role.more.skills" :key="`skill-${skillIndex}`">{{ skill }}</li>
              </ul>
            </div>

            <hr/>

            <div v-if="role.more.experience">
              <h3>You may also be able to demonstrate experience with:</h3>
              <ul>
                <li v-for="(experience, experienceIndex) in role.more.experience" :key="`experience-${experienceIndex}`">{{ experience }}</li>
              </ul>
            </div>

            <hr/>

            <div v-if="role.more.involved">
              <h3>You will be involved with the following work:</h3>
              <ul>
                <li v-for="(involved, involvedIndex) in role.more.involved" :key="`involved-${involvedIndex}`">{{ involved }}</li>
              </ul>
            </div>

            <hr/>

            <div v-if="role.more.perks">
              <h3>Perks:</h3>
              <ul>
                <li v-for="(perk, perkIndex) in role.more.perks" :key="`perk-${perkIndex}`">{{ perk }}</li>
              </ul>
            </div>

          </div>
        </div>
      </div>
    </div>

    <div class="container-fluid bg-tertiary">
      <div class="container py-3 py-md-5">
        <h2 class="fw-bold mt-0">Apply for {{ role.title }}</h2>
        <apply-for-position :role="role" :location-filter="role.locations" />
      </div>
    </div>

    <Hero background="hero-blank.png" background-style="min-height: 30vh;background-position: right center;">
      <div class="d-flex align-items-center gap-5">
        <h2 class="text-center">Or send your CV to: <a class="bmg-recruitment__cv-link btn-link text-white text-decoration-none" href="mailto:recruitment@believemoney.com">recruitment@<br class="d-block d-lg-none"/>believemoney.com</a></h2>
        <img src="@/assets/elements/paper-plane.svg" class="img-fluid h-75 d-none d-md-block" alt="Send Email" />
      </div>
    </Hero>

    <div class="container-fluid">
      <div class="container py-5">
        <div class="row">
          <div class="col text-center">
            <router-link to="/join-the-team" class="btn btn-bmg icon-right d-flex d-md-inline-block">View all positions <i class="bi bi-caret-right-fill"></i></router-link>
          </div>
        </div>
      </div>
    </div>

    <Footer />

  </div>

</template>

<script>
import Header from '../Sections/Scaffold/Header.vue'
import Footer from '../Sections/Scaffold/Footer.vue'

import { positions } from "../Sections/JoinTheTeam/job-positions.js";
import Hero from "../Sections/Hero.vue";
import ApplyForPosition from "../Sections/JoinTheTeam/ApplyForPosition.vue";

export default {
  name: 'Position',
  components: {
    ApplyForPosition,
    Hero,
    Header,
    Footer
  },
  data() {
    return {
      positions
    }
  },

  computed: {
    role() {
      return this.positions
          .find(x => x.slug === this.$route.params.position)
          .roles
          .find(x => x.slug === this.$route.params.role);
    }
  }
}
</script>

<style scoped lang="scss">
.position-points {
  ul {
    list-style: none;
    li {
      display: flex;
      gap: 0.5rem;
      line-height: 2rem;
      &::before {
        content: '\25CF';
        color: red;
      }
    }
  }
}
</style>
