<template>
  <div class="bmg">

    <Header />

    <Hero col="col-12 col-lg-5" background="hero-brands-believe-protect.png">
      <img src="@/assets/elements/brands/believe-protect/logo-light.svg" class="img-fluid" alt="Believe Protect Logo" />
      <h2 class="fw-bolder text-white mt-5">Making it easy to stay protected</h2>
      <div slot="right" class="col-12 col-lg-7">
        <img src="@/assets/elements/brands/believe-protect/hero.png" class="img-fluid ms-auto me-0 float-end d-flex" />
      </div>
    </Hero>

    <div class="container py-3">
      <h2 class="fw-bold text-center">At a glance</h2>
      <div class="row p-0 m-0 align-items-center">
        <div class="col-12 col-md-4 p-5">
          <StatCircle
              bg-left="#242449"
              bg-right="#6FAD82"
              title="3"
              subtitle="Core Products" />
        </div>
        <div class="col-12 col-md-4 p-5">
          <StatCircle
              bg-left="#242449"
              bg-right="#6FAD82"
              title="£300M+"
              subtitle="Insured" />
        </div>
        <div class="col-12 col-md-4 p-5">
          <StatCircle
              bg-left="#242449"
              bg-right="#6FAD82"
              title="3,500+"
              subtitle="Policies Written" />
        </div>
      </div>
    </div>


    <div class="container-fluid bg-tertiary">
      <div class="container py-5">
        <div class="row align-items-center">
          <div class="col-12 col-md-6">
            <img src="@/assets/elements/brands/believe-protect/logo.svg" class="img-fluid" alt="Believe Protect Logo" />
            <h5 class="fw-bold">About Believe Protect</h5>
            <p>Buying life insurance, income protection and critical illness cover can be complicated.</p>
            <p>
              By combining our own powerful, bespoke technology with our friendly team
              of expert advisors, we’re able to search out competitive quotes that are right for our customers.</p>
            <p>
              Believe Protect provides a free protection review to all Believe Money Group customers.
            </p>
          </div>
          <div class="col-12 col-md-6 d-flex align-items-center d-none d-md-block">
            <img src="@/assets/elements/brands/believe-protect/about.png" class="img-fluid d-flex mt-3 mx-auto" alt="About Believe Protect"/>
          </div>
        </div>
      </div>
    </div>

    <TrustPilot id="believe-protect" />

    <div class="container-fluid what-we-offer">
      <div class="container py-3 pb-5">
        <div class="row">
          <div class="col text-center">
            <h2 class="fw-bold py-3">What we offer</h2>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-4 px-5 pb-md-0 d-flex flex-column">
            <img src="@/assets/elements/offer/offer-01.svg" class="img-fluid" alt="We believe in freedom"/>
            <h5 class="fw-bold pt-2">
              Online Insurance Comparison
            </h5>
            <p>
              Quick and simple comparison of different insurance products helps customers make decisions easily.
            </p>
          </div>
          <div class="col-12 col-md-4 px-5 pb-md-0 d-flex flex-column">
            <img src="@/assets/elements/offer/offer-05.svg" class="img-fluid" alt="Real perks, not make believe"/>
            <h5 class="fw-bold pt-2">
              Affinity Whitelabel Solutions
            </h5>
            <p>
              We offer a tried and tested, easy-to-integrate, out-of-the-box solution for partner companies.
            </p>
          </div>
          <div class="col-12 col-md-4 px-5 pb-md-0 d-flex flex-column">
            <img src="@/assets/elements/offer/offer-04.svg" class="img-fluid" alt="We believe in fun"/>
            <h5 class="fw-bold pt-2">
              Offline Assisted Support
            </h5>
            <p>
              Where appropriate for the product, each client is supported by a fully qualified, friendly
              and experienced advisor who will ensure the best possible outcome is achieved.
            </p>
          </div>
        </div>
      </div>
    </div>

    <Hero col="col-12 col-lg-6" background="info-brands-believe-protect.png">
      <img src="@/assets/elements/brands/believe-protect/info.png" class="img-fluid d-flex mt-3 pb-4 mx-auto" alt="Information about Believe Protect"/>
      <div slot="right" class="col-12 col-lg-6">
        <ul class="list-unstyled">
          <li :key="`item-${index}`" v-for="(item, index) in items">
            <img :src="`${require(`@/assets/elements/tick.svg`)}`"
                 alt="Bullet Point"
                 :class="`py-2 me-3`"> {{ item }}</li>
        </ul>
        <p class="fw-bold">For more information or to apply then...</p>
        <a href="https://www.believeprotect.co.uk"
           target="_blank"
           class="btn btn-bmg icon-right d-flex d-md-inline-block">Visit Believe Protect <i class="bi bi-caret-right-fill"></i></a>
      </div>
    </Hero>

    <div class="container-fluid">
      <div class="container py-5">
        <div class="row">
          <div class="col text-center">
            <router-link to="/brands" class="btn btn-bmg icon-right d-flex d-md-inline-block">View our other brands <i class="bi bi-caret-right-fill"></i></router-link>
          </div>
        </div>
      </div>
    </div>

    <Footer />

  </div>
</template>

<script>
import Header from '../../Sections/Scaffold/Header.vue'
import Footer from '../../Sections/Scaffold/Footer.vue'
import StatCircle from "../../components/StatCircle";
import TrustPilot from "../../Sections/TrustPilot";
import Hero from "../../Sections/Hero.vue";

export default {
  name: 'Brands',
  components: {
    Hero,
    TrustPilot,
    StatCircle,
    Header,
    Footer
  },

  data() {
    return {
      items: [
        "Free advice from specialist insurance advisers",
        "Access to the UK’s leading insurance providers",
        "Cover available for 180 different serious illnesses",
        "Non-medical plans available"
      ]
    }
  }
}
</script>
