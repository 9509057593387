<template>
  <div class="bmg">

    <Header />

    <Hero col="col-12 col-lg-5" background="hero-brands-believe-specialist-finance.png">
      <img src="@/assets/elements/brands/believe-specialist-finance/logo-light.svg" class="img-fluid" alt="Believe Specialist Finance Logo" />
      <h2 class="fw-bolder text-white mt-5">Making specialist finance simple</h2>
      <div slot="right" class="col-12 col-lg-7">
        <img src="@/assets/elements/brands/believe-specialist-finance/hero.png" class="img-fluid ms-auto me-0 d-flex" />
      </div>
    </Hero>

    <div class="container-fluid bg-tertiary">
      <div class="container py-5">
        <div class="row align-items-center">
          <div class="col-12 col-md-6">
            <img src="@/assets/elements/brands/believe-specialist-finance/logo.svg" class="img-fluid" alt="Believe Specialist Finance Logo" />
            <h5 class="fw-bold">About Believe Specialist Finance</h5>
            <p>Not everyone deals in specialist and non-standard finance – but we do.</p>
            <p>
              By combining bespoke technology with our friendly expert team, we make it easy to find
              competitive quotes across various lending channels. Whether it’s to borrow money against
              a mortgage-free home,  short-term bridging finance or you are struggling to get a mortgage
              with a high street lender, we can help.
            </p>
            <p>
              A smooth and simple lending journey from beginning to end providing specialist finance
              tailored to each customer.
            </p>
          </div>
          <div class="col-12 col-md-6 d-flex align-items-center d-none d-md-block">
            <img src="@/assets/elements/brands/believe-specialist-finance/about.png" class="img-fluid d-flex mt-3 mx-auto" alt="About Believe Specialist Finance"/>
          </div>
        </div>
      </div>
    </div>

    <TrustPilot id="believe-specialist-finance" />

    <div class="container-fluid what-we-offer">
      <div class="container py-3 pb-5">
        <div class="row">
          <div class="col text-center">
            <h2 class="fw-bold py-3 ">What we offer</h2>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-4 px-5 pb-md-0 d-flex flex-column">
            <img src="@/assets/elements/offer/offer-00.svg" class="img-fluid" alt="We believe in freedom"/>
            <h5 class="fw-bold pt-2">
              Online Mortgage Comparison
            </h5>
            <p>
              Quick and simple comparison of different mortgage products helps customers make decisions easily.
            </p>
          </div>
          <div class="col-12 col-md-4 px-5 pb-md-0 d-flex flex-column">
            <img src="@/assets/elements/offer/offer-06.svg" class="img-fluid" alt="We believe in good health"/>
            <h5 class="fw-bold pt-2">
              Partner Decline Flow
            </h5>
            <p>
              Partners’ decline customers are able to access a carefully controlled panel of products
              for underserved homeowners.
            </p>
          </div>
          <div class="col-12 col-md-4 px-5 pb-md-0 d-flex flex-column">
            <img src="@/assets/elements/offer/offer-04.svg" class="img-fluid" alt="We believe in fun"/>
            <h5 class="fw-bold pt-2">
              Offline Assisted Support
            </h5>
            <p>
              Where appropriate for the product, each client is supported by a fully qualified, friendly
              and experienced advisor who will ensure the best possible outcome is achieved.
            </p>
          </div>
        </div>
      </div>
    </div>

    <Hero col="col-12 col-lg-6" background="info-brands-believe-specialist-finance.png">
      <img src="@/assets/elements/brands/believe-specialist-finance/info.png" class="img-fluid d-flex mt-3 pb-4 mx-auto" alt="Information about Believe Specialist Finance"/>
      <div slot="right" class="col-12 col-lg-6">
        <ul class="list-unstyled">
          <li :key="`item-${index}`" v-for="(item, index) in items">
            <img :src="`${require(`@/assets/elements/tick.svg`)}`"
                 alt="Bullet Point"
                 :class="`py-2 me-3`"> {{ item }}</li>
        </ul>
        <p class="fw-bold">For more information or to apply then...</p>
        <a href="https://www.believespecialistfinance.co.uk/"
           target="_blank"
           class="btn btn-bmg icon-right d-flex d-md-inline-block">Visit Believe Specialist Finance <i class="bi bi-caret-right-fill"></i></a>
      </div>
    </Hero>

    <div class="container-fluid">
      <div class="container py-5">
        <div class="row">
          <div class="col text-center">
            <router-link to="/brands" class="btn btn-bmg icon-right d-flex d-md-inline-block">View our other brands <i class="bi bi-caret-right-fill"></i></router-link>
          </div>
        </div>
      </div>
    </div>

    <Footer />

  </div>
</template>

<script>
import Header from '../../Sections/Scaffold/Header.vue'
import Footer from '../../Sections/Scaffold/Footer.vue'
import TrustPilot from "../../Sections/TrustPilot";
import Hero from "../../Sections/Hero.vue";

export default {
  name: 'Brands',
  components: {
    Hero,
    TrustPilot,
    // StatCircle,
    Header,
    Footer
  },
  data() {
    return {
      items: [
        "Mortgages from £10,000 to £20,000,000",
        "Secure against residential or business property",
        "Niche Mortgages",
        "Buy to Let",
        "Bridging Finance",
        "Buy To Let",
        "Commercial Finance"
      ]
    }
  }
}
</script>
