<template>
  <div class="bmg">
    <Header />
    <Hero col="col-12 col-lg-5 pe-3">
      <h1 class="fw-bolder">Loans, Mortgages & Protection</h1>
      <p>We're the UK's fastest-growing hybrid mortgage & loan platform connecting people and brands via powerful tech.</p>
      <p>The result? Smoother journeys, better outcomes and customers who love coming back.</p>
      <div slot="right" class="col-12 col-lg-7">
        <img src="@/assets/elements/home-hero-app.png" class="img-fluid" />
      </div>
    </Hero>
    <ClickTech/>
    <Believers/>
    <Brands/>
    <FinanceIssued/>
    <Reviews/>
    <Footer />
  </div>
</template>

<script>
import Header from '../Sections/Scaffold/Header.vue'
import Footer from '../Sections/Scaffold/Footer.vue'
import Hero from "../Sections/Hero";
import ClickTech from "../Sections/ClickTech";
import Believers from "../Sections/Believers";
import Brands from "../Sections/Brands";
import FinanceIssued from "../Sections/FinanceIssued";
import Reviews from "../Sections/Reviews";

export default {
  name: 'Home',
  components: {
    Reviews,
    FinanceIssued,
    Brands,
    Believers,
    ClickTech,
    Hero,
    Header,
    Footer
  }
}
</script>
