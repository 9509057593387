<template>
  <div class="circle d-flex flex-column justify-content-center align-items-center text-center"
  :style="`background: linear-gradient(330deg, ${bgRight} 0%, ${bgLeft} 60%) !important;`"
  >
    <h2 class="text-primary fw-bolder">{{ title }}</h2>
    <h3 class="text-white fw-bold">{{ subtitle }}</h3>
  </div>
</template>

<script>
export default {
  name: "StatCircle",
  props: {
    title: {
      type: String,
      default: "",
    },
    subtitle: {
      type: String,
      default: "",
    },
    bgLeft: {
      type: String,
      default: "",
    },
    bgRight: {
      type: String,
      default: "",
    },
  }
}
</script>

<style scoped lang="scss">
@import 'bootstrap';
.circle {
  width:100%;
  aspect-ratio: 1 / 1;
  border-radius: 50%;
  & h2 {
    font-size:2.5rem;
    font-weight: bold;
    padding:0;
    margin:0;
    @include media-breakpoint-down(lg) {
      font-size:1.5rem;
    }
    @include media-breakpoint-down(md) {
      font-size:2rem;
    }
  }
  & h3 {
    font-size:1.5rem;
    padding:0;
    margin:0.5rem 0;
    padding-inline: 1em;
    @include media-breakpoint-down(lg) {
      font-size:1rem;
    }
    @include media-breakpoint-down(md) {
      font-size:1.25rem;
    }
  }
}
</style>