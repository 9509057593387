<template>
  <div class="bmg">

    <Header/>

    <Hero col="col-12 col-lg-5" background-style="background-image:none;background-color:#183046;"    >
      <img src="@/assets/elements/brands/simply-money/logo-light.svg" class="img-fluid" alt="Simply Money Logo" />
      <h2 class="fw-bolder text-white mt-5">Making sourcing loans simple</h2>
      <div slot="right" class="col-12 col-lg-7">
        <img src="@/assets/elements/brands/simply-money/hero.png" class="img-fluid" />
      </div>
    </Hero>

    <div class="container py-3">
      <h2 class="fw-bolder text-center">At a glance</h2>
      <div class="row p-0 m-0 align-items-center">
        <div class="col-12 col-md-4 p-5">
          <StatCircle
              bg-left="#183046"
              bg-right="#183046"
              title="20+"
              subtitle="Access to over 20 lenders"/>
        </div>
        <div class="col-12 col-md-4 p-5">
          <StatCircle
              bg-left="#183046"
              bg-right="#183046"
              title="Online"
              subtitle="Self-serve Platform"/>
        </div>
        <div class="col-12 col-md-4 p-5">
          <StatCircle
              bg-left="#183046"
              bg-right="#183046"
              title="£25k"
              subtitle="Personal loans issued up to £25,000"/>
        </div>
      </div>
    </div>

    <div class="container-fluid bg-tertiary">
      <div class="container py-5">
        <div class="row align-items-center">
          <div class="col-12 col-md-6">
            <img src="@/assets/elements/brands/simply-money/logo.svg" class="img-fluid" alt="Simply Money Logo" />
            <h5 class="fw-bold">About Simply Money</h5>
              <p>Personal loan platform on a mission to make sourcing loans simple.</p>
              <p>Simply Money is our flagship personal loan platform offering quick quotes
                and high-quality customer service. It provides consumers with real-rate offers
                unique to their circumstances, with connectivity and access through to our
                regulated first and second charge mortgage products. </p>
          </div>
          <div class="col-12 col-md-6 d-flex align-items-center d-none d-md-block">
            <img src="@/assets/elements/brands/simply-money/about.png" class="img-fluid d-flex mt-3 mx-auto" alt="About Simply Money"/>
          </div>
        </div>
      </div>
    </div>

    <TrustPilot id="simply-money"/>

    <div class="container-fluid what-we-offer">
      <div class="container py-3 pb-5">        <div class="row">
        <div class="col text-center">
          <h2 class="fw-bold py-3 ">What we offer</h2>
        </div>
      </div>
        <div class="row">
          <div class="col-12 col-md-4 px-5 pb-md-0 d-flex flex-column">
            <img src="@/assets/elements/offer/offer-01.svg" class="img-fluid" alt="We believe in freedom"/>
            <h5 class="fw-bold pt-2">
              Online Loan Comparison
            </h5>
            <p>
              Quick and simple comparison of different loan products helps customers make decisions easily.
            </p>
          </div>
          <div class="col-12 col-md-4 px-5 pb-md-0 d-flex flex-column">
            <img src="@/assets/elements/offer/offer-02.svg" class="img-fluid" alt="We believe in nurture"/>
            <h5 class="fw-bold pt-2">
              Real Rate Offers
            </h5>
            <p>
              Customers are protected from misleading ‘teaser rates’, being offered only the real
              rates they’ll pay if they take up the loan.
            </p>
          </div>
          <div class="col-12 col-md-4 px-5 pb-md-0 d-flex flex-column">
            <img src="@/assets/elements/offer/offer-03.svg" class="img-fluid" alt="We believe in you"/>
            <h5 class="fw-bold pt-2">
              Route To Secured Channels
            </h5>
            <p>
              The digital process allows customers to link to our online portal where they can enter
              brief information for digital KYC verification, before accessing status updates and
              secured products.
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-4 px-5 pb-md-0 d-flex flex-column">
            <img src="@/assets/elements/offer/offer-04.svg" class="img-fluid" alt="We believe in fun"/>
            <h5 class="fw-bold pt-2">
              Offline Assisted Support
            </h5>
            <p>
              Where appropriate for the product, each client is supported by a fully qualified, friendly
              and experienced advisor who will ensure the best possible outcome is achieved.
            </p>
          </div>
          <div class="col-12 col-md-4 px-5 pb-md-0 d-flex flex-column">
            <img src="@/assets/elements/offer/offer-05.svg" class="img-fluid" alt="Real perks, not make believe"/>
            <h5 class="fw-bold pt-2">
              Affinity Whitelabel Solutions
            </h5>
            <p>
              We offer a tried and tested, easy-to-integrate, out-of-the-box solution for partner companies.
            </p>
          </div>
          <div class="col-12 col-md-4 px-5 pb-md-0 d-flex flex-column">
            <img src="@/assets/elements/offer/offer-06.svg" class="img-fluid" alt="We believe in good health"/>
            <h5 class="fw-bold pt-2">
              Partner Decline Flow
            </h5>
            <p>
              Partners’ decline customers are able to access a carefully controlled panel of personal loans,
              hire purchase and second charge loan products for underserved homeowners.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="container-fluid information">
      <div class="container py-5">
        <div class="row align-items-center">
          <div class="col-12 col-md-6 d-flex align-items-center">
            <img src="@/assets/elements/brands/simply-money/info.png" class="img-fluid d-flex mt-3 pb-4 mx-auto pe-5" alt="Information about Simply Money"/>
          </div>
          <div class="col-12 col-md-6 py-5">
            <p>
              Our online loan platform is designed to make finance simple, offering an optimised, managed lending panel
              for both consumers and lending partners. Our platform is an online loan marketplace supported by call
              centre expertise that gives access across all lending verticals within the Believe Money Group.
            </p>
            <p>
              Our direct-to-consumer acquisition strategy delivers strong ROI, and we are trusted by multiple tier 1
              lenders who use our platform for decline solutions. Our flagship brand, Simply Money, provides a gateway
              to all the Believe Money Group lending verticals, delivering industry-leading earning per lead or
              referral.
            </p>
            <p>
              We also offer a white label API gateway that aggregates volumes for our partners, giving quick, secure and
              compliant access to our Group’s
              lending marketplace.
            </p>
            <p class="fw-bold">For more information or to apply then...</p>
            <a href="https://www.simplymoney.com/"
               target="_blank"
               class="btn btn-bmg icon-right d-flex d-md-inline-block mt-3">Visit Simply Money <i class="bi bi-caret-right-fill"></i></a>

          </div>
        </div>
      </div>
    </div>

    <div class="container-fluid">
      <div class="container py-5">
        <div class="row">
          <div class="col text-center">
            <router-link to="/brands" class="btn btn-bmg icon-right d-flex d-md-inline-block">View our other brands <i class="bi bi-caret-right-fill"></i></router-link>
          </div>
        </div>
      </div>
    </div>

    <Footer/>

  </div>
</template>

<script>
import Header from '../../Sections/Scaffold/Header.vue'
import Footer from '../../Sections/Scaffold/Footer.vue'
import StatCircle from "../../components/StatCircle";
import TrustPilot from "../../Sections/TrustPilot";
import Hero from "../../Sections/Hero.vue";

export default {
  name: 'Brands',
  components: {
    Hero,
    TrustPilot,
    StatCircle,
    Header,
    Footer
  }
}
</script>

<style>
.information {
  background-color: #73ACB9;
}
</style>
