<template>
    <footer class="container-fluid bmg-footer">
      <div class="container">
        <div class="row footer__links">
          <div class="col-6 col-md-3 col-lg-2 order-last order-md-first">
            <div class="d-flex flex-column gap-4">
              <img src="@/assets/logo-white.png" class="img-fluid" alt="Believe Money Group"/>
              <img src="@/assets/bmg-iip.png" class="img-fluid" alt="Investors In People"/>
              <img src="@/assets/bmg-bscb-ms-2022.png" class="img-fluid" alt="Mortgage Strategy Awards 2022 - Best Second Charge Broker"/>
              <img src="@/assets/bmg-natwest-2022.png" class="img-fluid" alt="Local Hero Mortgage Awards Winner 2022 "/>
            </div>
          </div>
          <div class="col-12 col-md-3 pb-4 pb-md-0 offset-lg-1">
            <h5>Our Brands</h5>
            <hr/>
            <ul class="nav flex-column">
              <li class="navitem mb-2"><RouterLink to="/brands/simply-money" class="nav-link p-0 text-white">Simply Money</RouterLink></li>
              <li class="navitem mb-2"><RouterLink to="/brands/believe-finance" class="nav-link p-0 text-white">Believe Finance</RouterLink></li>
              <li class="navitem mb-2"><RouterLink to="/brands/believe-loans" class="nav-link p-0 text-white">Believe Loans</RouterLink></li>
              <li class="navitem mb-2"><RouterLink to="/brands/believe-specialist-finance" class="nav-link p-0 text-white">Believe Specialist Finance</RouterLink></li>
              <li class="navitem mb-2"><RouterLink to="/brands/believe-protect" class="nav-link p-0 text-white">Believe Protect</RouterLink></li>
            </ul>
          </div>
          <div class="col-12 col-md-3 pb-4 pb-md-0">
            <h5>Products</h5>
            <hr/>
            <ul class="nav flex-column">
              <li class="navitem mb-2"><RouterLink to="/brands/simply-money" class="nav-link p-0 text-white">Personal Loans</RouterLink></li>
              <li class="navitem mb-2"><RouterLink to="/brands/believe-finance" class="nav-link p-0 text-white">1st Charge Mortgages</RouterLink></li>
              <li class="navitem mb-2"><RouterLink to="/brands/believe-loans" class="nav-link p-0 text-white">2nd Charge Mortgages</RouterLink></li>
              <li class="navitem mb-2"><RouterLink to="/brands/believe-specialist-finance" class="nav-link p-0 text-white">Specialist Mortgage</RouterLink></li>
              <li class="navitem mb-2"><RouterLink to="/brands/believe-protect" class="nav-link p-0 text-white">Protection</RouterLink></li>
            </ul>
          </div>
          <div class="col-12 col-md-3 pb-4 pb-md-0">
            <h5>About Us</h5>
            <hr/>
            <ul class="nav flex-column">
              <li class="navitem mb-2"><RouterLink to="/about" class="nav-link p-0 text-white">About</RouterLink></li>
              <li class="navitem mb-2"><RouterLink to="/technology" class="nav-link p-0 text-white">Technology</RouterLink></li>
              <li class="navitem mb-2"><RouterLink to="/join-the-team" class="nav-link p-0 text-white">Join The Team</RouterLink></li>
              <li class="navitem mb-2"><RouterLink to="/contact" class="nav-link p-0 text-white">Contact Us</RouterLink></li>
              <li class="navitem mb-2"><RouterLink to="/privacy" class="nav-link p-0 text-white">Privacy Policy</RouterLink></li>
              <li class="navitem mb-2"><RouterLink to="/terms" class="nav-link p-0 text-white">Terms &amp; Conditions</RouterLink></li>
              <li class="navitem mb-2"><RouterLink to="/cookies" class="nav-link p-0 text-white">Cookie Policy</RouterLink></li>
            </ul>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <p class="small text-white text-center pt-3">
              Believe Money Group.  Believe House, Heavens Walk, Doncaster, DN4 5HZ.  Company Number: 10712693.  ICO number: ZB212625.</p>
          </div>
        </div>
      </div>
    </footer>
</template>

<script>
export default {
  name:"Footer",
  methods: {
    scrollTop() {
        window.scrollTo(0, 0);
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/scss/index.scss';
.bmg-footer {
  // linear gradient from the bottom right
  background: linear-gradient(135deg, #242449 50%, #D03B4E 100%);
}
.text-white {
  color:#ffffff;
}
.footer__links {
  padding: 3rem 0 0 0;
  h5 {
    color:$white;
    text-transform: uppercase;
    padding:0;
    margin:0;
  }
  hr {
    color:$white;
    opacity: 0.8;
    width:75%;
  }
  a.nav-link, .text-white {
    color:#fff !important;
  }
  .router-link-exact-active {
    text-decoration: underline;
  }
}
.footer__awards {
  padding-bottom:3rem;
}
</style>
