<template>
  <div class="bmg">
    <Header />
    <Hero
        container-style="mb-5 pb-5"
        background="hero-about.jpg"
        background-style="background-position: center bottom;min-height: 60vh;"
        background-mobile="hero-blank.png"
        background-mobile-style="background-position: center top;min-height: 30vh;"
        col="col-12 col-lg-5">
      <h1 class="fw-bolder">About Us</h1>
      <h5>Believe Money Group was<br/>established in 2017.</h5>
      <h5>Our vision was simple, we believed we could make the financial services market a better place by creating a range of products designed with the customer at the heart.</h5>
    </Hero>
    <AboutBelieve/>
    <Hero>
        <video autoplay muted class="ratio ratio-16x9 rounded" controls>
          <source :src="`${require(`@/assets/video/about-believe.mp4`)}`" type="video/mp4" />
        </video>
    </Hero>
    <WeBelieve/>

    <Hero background="hero-about-brands.png" col="col-12 col-lg-5"
          background-style="background-position: left center;">
      <h1 class="fw-bolder">Market leading brands using technology to maximise conversions</h1>

    </Hero>

    <AwardWinningService/>
    <Footer />
  </div>
</template>

<script>
import Header from '../Sections/Scaffold/Header.vue'
import Footer from '../Sections/Scaffold/Footer.vue'
import AboutBelieve from "../Sections/AboutBelieve";
import WeBelieve from "../Sections/WeBelieve";
import AwardWinningService from "../Sections/AwardWinningService";
import Hero from "../Sections/Hero.vue";

export default {
  name: 'Home',
  components: {
    Hero,
    Header,
    Footer,
    AboutBelieve,
    WeBelieve,
    AwardWinningService
  }
}
</script>
