<template>
  <div class="container-fluid bg-tertiary believers-in-you">
    <div class="container pt-5 pb-5">
      <div class="row">
        <div class="col-12 col-md-6 col-lg-4 px-5 position-relative">
          <img src="@/assets/elements/benefits/you.svg" class="img-fluid" alt="We believe in you"/>
          <h3 class="fw-bold">
            Progression
          </h3>
          <p>
            We believe in promoting from within. Whether you start as an apprentice or mortgage
            advisor we want you to develop and fulfil your potential. We nurture and promote our
            senior management team have been with us from the beginning.
          </p>
          <img src="@/assets/elements/benefits/rocket.svg"
               class="img-fluid position-absolute believers-in-you__rocket d-none d-lg-block"
               alt="We believe in you"/>
        </div>
        <div class="col-12 col-md-6 col-lg-4 px-5 pb-md-0 offset-md-0 offset-lg-2 position-relative">
          <img src="@/assets/elements/benefits/nurture.svg" class="img-fluid" alt="We believe in nurture"/>
          <h3 class="fw-bold">
            Training
          </h3>
          <p >
            Individual technical training is provided to all employees who work with us. Academy
            scheme to train and qualify new recruits into the FCA regulated industry. We nurture
            and promote from within and our senior management team have been with us from the beginning.
          </p>
          <img src="@/assets/elements/benefits/training.svg"
               class="img-fluid position-absolute believers-in-you__training d-none d-lg-block"
               alt="We believe in you"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "JoinTheTeamInYou"
}
</script>


<style scoped lang="scss">
.believers-in-you__rocket {
  right: -50%;
  bottom: -10%;
  width:50%;
}
.believers-in-you__training {
  right: -50%;
  top:0;
  bottom: 0;
  width:60%;
}
</style>
