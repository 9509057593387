
export const recruitmentApi = "https://eoxralpnypjzj9h.m.pipedream.net";

export const locations = {
  doncaster: "Doncaster",
  stockport: "Stockport"
}

export const positions = [
  {
    title: "Contact Centre",
    slug: "contact-centre",
    roles: [
      {
        slug: "contact-centre-associate",
        title: "Contact Centre Associate",
        description: "We have an exciting opportunity to work with a team of exceptionally skilled, fun and driven people.",
        locations: [
            locations.stockport
        ],
        more: {
          description: [
            "We have an exciting opportunity to work with a team of exceptionally skilled, fun and driven people.",
            "We're on a mission to be best in class in every product vertical we operate.",
            "Not easy, but we'll do it with great people and great tech, providing 1st class customer service!",
            "You will have the chance to make a huge impact on this established business, opportunities and growth is in our DNA - we BELIEVE in developing from within!",
            "Make your mark in the finance industry with the best in the business!"
          ],
          department: "Contact Centre",
          responsibleTo: "Operations Director",
          hours: [
            "Monday to Thursday 9am - 7pm",
            "Friday 9am - 2pm",
            "(Flexitime +/- hour)"
          ],
          skills: [
            "Fantastic telephone manner",
            "Resilient and hard-working",
            "Understand your role within Treating Customers Fairly (TCF)",
            "High level of attention to detail"
          ],
          experience: [
            "Maintaining business logs using a CRM system",
            "Meeting departmental SLAs and targets",
            "Handling expressions of customer dissatisfaction",
            "Maintaining the highest standards as the first point of contact for customers"
          ],
          involved: [
            "Handling a high volume of calls whilst maintaining a professional image",
            "Engaging with third party introduced customers and handling any customer objections to a satisfactory conclusion",
            "Collecting and clarifying customer information and referring customers onto the relevant department and customer resolution",
            "Receiving inbound calls and making outbound calls to prospective new customers"
          ],
          perks: [
            "Company pension",
            "Westfield Health & Therapy Sessions",
            "Quarterly incentives",
            "Free fruit",
            "Free parking",
            "Dress-down Fridays"
          ]
        }
      }
    ]
  },
  {
    title: "Projects & Tech",
    slug: "projects-tech",
    roles: [
      {
        slug: "project-manager",
        title: "Project Manager",
        description: "We have an exciting opportunity to work with a team of exceptionally skilled, fun and driven people.",
        locations: [
            locations.doncaster,
            locations.stockport
        ],
        more: {
          description: [
            "We have an exciting opportunity to work with a team of exceptionally skilled, fun and driven people.",
            "We're on a mission to be best in class in every product vertical we operate.",
            "Not easy, but we'll do it with great people and great tech, providing 1st class customer service!",
            "You will have the chance to make a huge impact on this established business, opportunities and growth is in our DNA - we BELIEVE in developing from within!",
            "Make your mark in the finance industry with the best in the business!"
          ],
          department: "Believe Advisor",
          responsibleTo: "Optimisation Director",
          hours: [
            "Monday to Thursday 9am - 5:30pm",
            "Friday 9am - 3pm",
            "(Flexitime +/- hour)"
          ],
          skills: [
            "Teamwork",
            "Problem solving",
            "Self-management",
            "Willingness to learn"
          ],
          experience: [
            "Professional certification in a project discipline or the willingness to study for professional certification",
            "Experience of working in a project environment and stakeholder management or requirement gathering",
            "Strong written and oral communication skills",
            "Experience in successfully leading projects - desirable",
            "Experience of working with different project delivery methodologies - desirable"
          ],
          involved: [
            "Assist in project planning",
            "Assist with the production of comprehensive project plans and documentation to enable the project to be managed against a clear set of defined deliverables and milestones",
            "Monitoring, measuring, reviewing and updating project process, adjusting project plans and implementing remedial plans and informing all relevant parties",
            "Conduct an evaluation of the project and any follow-on action recommendations as required",
            "Produce project documentation and create knowledge libraries with the end goal of using this documentation to train and support the end users",
            "Provide guidance to those involved in the project by defining work and ensuring deadlines are understood and adhered to and that project objectives are clearly articulated and understood",
            "To motivate and develop group employees through leadership and example, identifying and agreeing learning and development needs as required"
          ],
          perks: [
            "Company pension",
            "Westfield Health & Therapy Sessions",
            "Quarterly incentives",
            "Free fruit",
            "Free parking",
            "Dress-down Fridays"
          ]
        }
      }
    ]
  },
  {
    title: "Regulated Advice",
    slug: "regulated-advice",
    roles: [
      {
        slug: "bl-advisor",
        title: "Mortgage Advisor - 2nd Charge Mortgages",
        description: "We have an exciting opportunity to work with a team of exceptionally skilled, fun and driven people.",
        locations: [
            locations.doncaster
        ],
        more: {
          description: [
            "We have an exciting opportunity to work with a team of exceptionally skilled, fun and driven people.",
            "We're on a mission to be best in class in every product vertical we operate.",
            "Not easy, but we'll do it with great people and great tech, providing 1st class customer service!",
            "You will have the chance to make a huge impact on this established business, opportunities and growth is in our DNA - we BELIEVE in developing from within!",
            "Make your mark in the finance industry with the best in the business!"
          ],
          department: "Believe Loans",
          responsibleTo: "Operations Director",
          hours: [
            "Monday to Thursday 9am - 7pm",
            "Friday 9am - 3pm",
            "(Flexitime +/- hour)"
          ],
          skills: [
            "Ability to close sales opportunities",
            "Understanding credit reports",
            "Working within a regulated environment and the expectations from this.",
            "Willingness to learn, develop and support your colleagues"
          ],
          experience: [
            "Maintaining business logs using a CRM system",
            "Meeting departmental SLAs and targets",
            "Knowledge of the financial services industry",
            "Managing your own case load and sales pipeline"
          ],
          involved: [
            "Provide high quality professional independent advice and explain different types of second charge mortgages available",
            "To be proactive, hardworking, flexible and able to work under own initiative but also a team player",
            "Working with case managers on loan sales to make sure the advice provided and products sold is accurate and to the highest of standards",
            "Attend training sessions and product updates from internal and external partners"
          ],
          perks: [
            "Company pension",
            "Westfield Health & Therapy Sessions",
            "Quarterly incentives",
            "Free fruit",
            "Free parking",
            "Dress-down Fridays"
          ]
        }
      }
    ]
  },
  {
    title: "Protection",
    slug: "protection",
    roles: [
      {
        slug: "protection-advisor",
        title: "Protection Advisor",
        description: "We have an exciting opportunity to work with a team of exceptionally skilled, fun and driven people.",
        locations: [
            locations.doncaster
        ],
        more: {
          description: [
            "We have an exciting opportunity to work with a team of exceptionally skilled, fun and driven people.",
            "We're on a mission to be best in class in every product vertical we operate.",
            "Not easy, but we'll do it with great people and great tech, providing 1st class customer service!",
            "You will have the chance to make a huge impact on this established business, opportunities and growth is in our DNA - we BELIEVE in developing from within!",
            "Make your mark in the finance industry with the best in the business!"
          ],
          department: "Believe Protect",
          responsibleTo: "Operations Manager",
          hours: [
            "Monday to Thursday 9am - 7pm",
            "Friday 9am - 3pm",
            "(40 hours inclusive of breaks)"
          ],
          skills: [
            "Fantastic telephone manner",
            "Resilient and hard-working",
            "Understand your role within Treating Customers Fairly (TCF)",
            "High level of attention to detail"
          ],
          experience: [
            "Maintaining business logs using a CRM system",
            "Meeting departmental SLAs and targets",
            "Handling expressions of customer dissatisfaction",
            "Maintaining the highest standards as the first point of contact for customers"
          ],
          involved: [
            "To offer professional Protection advice solutions accordance with FCA regulations, providing the most suitable protection advice for the company's clients",
            "The delivery of a first-class customer journey by maximising customer satisfaction and conversion through providing \"right customer outcomes\".",
            "Fact-find effectively to establish the client’s current situation and secured lending, high attention to detail to make sure all relevant information is noted.",
            "Provide high quality professional independent Protection advice and explain different types of protection available.",
            "To organise your calendar with customers and providers.",
            "Manage your own workflows to fulfil your everyday duties.",
            "Ensure all regulatory documentation completed within the required timescales.",
            "To maintain knowledge of the financial services industry.",
            "Keep up to date with new protection products and changes in providers criteria.",
            "Meeting performance targets.",
            "Embrace company values and promote them effectively alongside the Believe brand.",
            "Proactively pre-empt issues with regards to customers and resolve problems arising.",
            "Understand what Treating Customers Fairly (TCF) means and embed this fully within your work.",
            "Attend internal or external meetings as required."
          ],
          perks: [
            "Company pension",
            "Westfield Health & Therapy Sessions",
            "Quarterly incentives",
            "Free fruit",
            "Free parking",
            "Dress-down Fridays"
          ]
        }
      }
    ]
  }
];
