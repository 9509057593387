<template>
  <div class="bmg">

    <Header />

    <Hero col="col-12 col-lg-5">
      <h1 class="fw-bolder">Our Brands</h1>
      <p>Our Believe brands are market leaders using technology to maximise conversions.</p>
      <div slot="right" class="col-12 col-lg-7">
        <img src="@/assets/elements/brand-hero-app.png" class="img-fluid" />
      </div>
    </Hero>

    <div class="container-fluid bg-tertiary">
      <div class="container py-4">
        <div class="row align-items-center">
          <div class="col-12 col-md-6">
            <img src="@/assets/elements/brands/simply-money/logo.svg" class="img-fluid" alt="Simply Money Logo" />
            <h5 class="fw-bolder">Personal loan platform on a mission to make sourcing loans simple</h5>
            <p>Quick quotes and real customer service for a smooth, satisfying experience. Our multichannel loan platform offers price comparison and credit broking that’s quick, simple and jargon-free.</p>
            <router-link to="/brands/simply-money" class="btn btn-bmg icon-right d-flex d-lg-inline-block">Find out more <i class="bi bi-caret-right-fill"></i></router-link>
          </div>
          <div class="col-12 col-md-6 d-flex align-items-center d-none d-md-block">
            <img src="@/assets/elements/brands/simply-money/preview.png" class="img-fluid d-flex mt-3 mx-auto" alt="Simply Money Website"/>
          </div>
        </div>
      </div>
    </div>

    <div class="container-fluid">
      <div class="container py-5">
        <div class="row align-items-center">
          <div class="col-12 col-md-6 order-0 order-md-1 offset-0 offset-md-1">
            <img src="@/assets/elements/brands/believe-loans/logo.svg" class="img-fluid" alt="Believe Loans Logo" />
            <h5 class="fw-bolder">The UK’s fastest-growing second charge mortgage broker</h5>
            <p>Unlocking loan possibilities for customers across the UK. From big purchases to holidays and home improvements, it’s quick and easy to find the right quote and apply.</p>
            <router-link to="/brands/believe-loans" class="btn btn-bmg icon-right d-flex d-lg-inline-block">Find out more <i class="bi bi-caret-right-fill"></i></router-link>
          </div>
          <div class="col-12 col-md-5 d-flex align-items-center d-none d-md-block">
            <img src="@/assets/elements/brands/believe-loans/preview.png" class="img-fluid d-flex mt-3 mx-auto" alt="Believe Loans Website"/>
          </div>
        </div>
      </div>
    </div>

    <div class="container-fluid bg-tertiary">
      <div class="container py-5 py-md-3">
        <div class="row align-items-center">
          <div class="col-12 col-md-6">
            <img src="@/assets/elements/brands/believe-finance/logo.svg" class="img-fluid" alt="Believe Finance Logo" />
            <h5 class="fw-bolder">Specialists in first charge remortgages and protection</h5>
            <p>Expert brokers making mortgages simple. With bespoke solutions for multiple mortgage products, we offer omnichannel customer journeys that make it easy to get a great quote and apply.</p>
            <router-link to="/brands/believe-finance" class="btn btn-bmg icon-right d-flex d-lg-inline-block">Find out more <i class="bi bi-caret-right-fill"></i></router-link>
          </div>
          <div class="col-12 col-md-5 offset-0 offset-md-1 d-flex align-items-center d-none d-md-block">
            <img src="@/assets/elements/brands/believe-finance/preview.png" class="img-fluid d-flex mt-3 mx-auto" alt="Believe Finance Website"/>
          </div>
        </div>
      </div>
    </div>

    <div class="container-fluid">
      <div class="container py-5">
        <div class="row align-items-center">
          <div class="col-12 col-md-6 order-0 order-md-1 offset-0 offset-md-1">
            <img src="@/assets/elements/brands/believe-specialist-finance/logo.svg" class="img-fluid" alt="Believe Specialist Finance Logo" />
            <h5 class="fw-bolder">Specialist and non-standard finance made easy</h5>
            <p>Not everyone deals in specialist and non-standard finance. Whether our customers want to borrow money against a mortgage-free home or are looking for short-term bridging finance, we can help.</p>
            <router-link to="/brands/believe-specialist-finance" class="btn btn-bmg icon-right d-flex d-md-inline-block">Find out more <i class="bi bi-caret-right-fill"></i></router-link>
          </div>
          <div class="col-12 col-md-5 d-flex align-items-center d-none d-md-block">
            <img src="@/assets/elements/brands/believe-specialist-finance/preview.png" class="img-fluid d-flex mt-3 mx-auto" alt="Believe Specialist Finance Website"/>
          </div>
        </div>
      </div>
    </div>

    <div class="container-fluid bg-tertiary">
      <div class="container py-5">
        <div class="row align-items-center">
          <div class="col-12 col-md-6">
            <img src="@/assets/elements/brands/believe-protect/logo.svg" class="img-fluid" alt="Believe Protect Logo" />
            <h5 class="fw-bolder">Bespoke insurance products available to all Group customers</h5>
            <p>Specialist insurance advisors with access to leading providers. We help Believe Group loan and mortgage customers benefit from bespoke insurance policies designed around their protection needs.</p>
            <router-link to="/brands/believe-protect" class="btn btn-bmg icon-right d-flex d-lg-inline-block">Find out more <i class="bi bi-caret-right-fill"></i></router-link>
          </div>
          <div class="col-12 col-md-5 offset-0 offset-md-1 d-flex align-items-center d-none d-md-block">
            <img src="@/assets/elements/brands/believe-protect/preview.png" class="img-fluid d-flex mt-3 mx-auto" alt="Believe Protect Website"/>
          </div>
        </div>
      </div>
    </div>

    <Partners/>

    <Footer />

  </div>
</template>

<script>
import Header from '../Sections/Scaffold/Header.vue'
import Footer from '../Sections/Scaffold/Footer.vue'
import Partners from "../Sections/Partners";
import Hero from "../Sections/Hero.vue";

export default {
  name: 'Brands',
  components: {
    Hero,
    Partners,
    Header,
    Footer
  }
}
</script>