<template>
  <div class="bmg">
    <Header />
    <Hero col="col-12 col-lg-5">
      <h1 class="fw-bolder">Page not found</h1>
    </Hero>
    <div class="container-fluid">
      <div class="container text-center py-5">
        <div class="row">
          <div class="col">
            <p>
              The page you are looking for does not exist.
            </p>
            <p>
              <a href="/" class="btn btn-primary">
                Go to home page</a>
            </p>
          </div>
        </div>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
import Header from '../Sections/Scaffold/Header.vue'
import Footer from '../Sections/Scaffold/Footer.vue'
import Hero from "../Sections/Hero.vue";

export default {
  name: '404',
  components: {
    Hero,
    Header,
    Footer
  }
}
</script>
