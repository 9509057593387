<template>
  <div class="bmg">
    <Header />
    <Hero col="col-12 col-lg-5" background="hero-contact.png">
      <h1 class="fw-bolder">Contact Us</h1>
    </Hero>
    <ContactSection/>
    <Footer />
  </div>
</template>

<script>
import Header from '../Sections/Scaffold/Header.vue'
import Footer from '../Sections/Scaffold/Footer.vue'
import ContactSection from "../Sections/Contact";
import Hero from "../Sections/Hero.vue";

export default {
  name: 'Contact',
  components: {
    Hero,
    ContactSection,
    Header,
    Footer
  }
}
</script>
