<template>
  <div class="container-fluid award-winning-service">
    <div class="container text-center pt-5 pb-5">
      <h2 class="text-center fw-bolder">Award Winning Service</h2>
      <img src="../assets/elements/bmg-awards.svg" alt="Believe Money Group Awards" class="img-fluid d-none d-md-block" />
      <img src="../assets/elements/bmg-awards-mobile.svg" alt="Believe Money Group Awards" class="img-fluid d-md-none" />
    </div>
  </div>
</template>

<script>
export default {
  name: "AwardWinningService",
}
</script>

<style scoped lang="scss">
</style>