<template>
  <div>
    <div class="container-fluid partners mt-4 mb-4">
      <h2 class="text-center fw-bolder">Brand Partners</h2>
      <div class="container d-flex gap-2 gap-md-4 justify-content-center lenderRow align-items-center flex-wrap">
        <img src="@/assets/elements/partners/zopa.png" alt="Zopa">
        <img src="@/assets/elements/partners/shawbrook.jpg" alt="Shawbrook Bank">
        <img src="@/assets/elements/partners/bamboo.png" alt="Bamboo">
        <img src="@/assets/elements/partners/utb.jpg" alt="United Trust Bank">
        <img src="@/assets/elements/partners/tandem.png" alt="Tandem">
        <img src="@/assets/elements/partners/transunion.png" alt="Transunion">
        <img src="@/assets/elements/partners/equifax.png" alt="Equifax">
        <img src="@/assets/elements/partners/credit-karma.png" alt="Credit Karma">
        <img src="@/assets/elements/partners/nerd-wallet.png" alt="Nerd Wallet">
        <img src="@/assets/elements/partners/confused.png" alt="Confused.com">
        <img src="@/assets/elements/partners/switcha.png" alt="Switcha.com">
        <img src="@/assets/elements/partners/monevo.png" alt="Monevo.com">
        <img src="@/assets/elements/partners/clearscore.png" alt="ClearScore">
        <img src="@/assets/elements/partners/totallymoney.png" alt="Totally Money">
        <img src="@/assets/elements/partners/plata.png" alt="Plata">
      </div>
    </div>
  </div>
</template>

<script>
    export default {
        
    }
</script>

<style>
.lenderRow img {
    height: auto;
    max-width: 150px;
}
.lenderRow img {
    padding: 10px 10px 10px 0px;
}
</style>