<template>
  <div class="container-fluid bmg-container reviews">
    <div class="container py-4 py-md-5 text-center">
      <img src="@/assets/elements/believe-in-us.png" class="img-fluid m-auto mb-4" alt="Believe in us"/>
      <h2 class="fw-bolder">Believe in us</h2>
      <TrustPilot id="believe-loans" no-background/>
<!--      <div id="customer-reviews" class="carousel carousel-dark slide" data-bs-ride="carousel">-->
<!--        <div class="carousel-indicators">-->
<!--          <button-->
<!--            type="button"-->
<!--            data-bs-target="#customer-reviews"-->
<!--            :data-bs-slide-to="index"-->
<!--            :aria-current="`${index == 0 ? 'true' : 'false'}`"-->
<!--            aria-label="`${review.name}`"-->
<!--            :class="`${index == 0 ? 'active' : ''}`"-->
<!--            v-for="(review, index) in reviews"-->
<!--            :key="`indicator-${index}`"></button>-->
<!--        </div>-->
<!--        <div class="carousel-inner">-->
<!--          <div :class="`carousel-item ${index == 0 ? 'active' : ''}`"-->
<!--               v-for="(review, index) in reviews"-->
<!--               :key="`slide-${index}`">-->
<!--            <div class="row">-->
<!--              <div class="col col-md-6 offset-md-3 d-flex flex-column justify-content-center reviews__quotes">-->
<!--                <p v-for="(quote, quoteIndex) in review.quote" :key="`side-${index}-p-${quoteIndex}`" class="fw-bold fs-5">-->
<!--                  {{ quote }}-->
<!--                </p>-->
<!--              </div>-->
<!--            </div>-->
<!--            <p class="author pb-5">-->
<!--              {{ review.name }} on {{ review.brand }}-->
<!--            </p>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
    </div>
  </div>
</template>

<script>
import * as Bootstrap from 'bootstrap'
import TrustPilot from "./TrustPilot.vue";

export default {
  name: "Reviews",
  components: {TrustPilot},
  mounted() {
    let customeReviews = document.querySelector('#customer-reviews')
    new Bootstrap.Carousel(customeReviews)
  },
  data() {
    return {
      reviews:[
        {
          name: "Richard",
          brand: "Believe Loans",
          quote: [
            "Cannot rate the service here highly enough. Everywhere else had turned me down but " +
            "was able to get the help and loan needed from Believe loans."
          ]
        },{
          name: "Chris",
          brand: "Believe Finance",
          quote: [
            "The customer service team are legends! They are always there to answer questions and help you."
          ]
        }, {
          name: "John",
          brand: "Believe Loans",
          quote: [
            "I was able to get a loan from Believe loans. I am so happy I found them."
          ]
        }
      ]
    }
  }
}
</script>

<style scoped lang="scss">
@import 'bootstrap';
.reviews {
  .reviews__quotes {
    height: 200px;
    @include media-breakpoint-down(md) {
      height: 300px;
    }
    p {
      line-height: 30px;
    }
    p:last-child::after {
      transform: rotate(180deg);
    }
    p:first-child::before, p:last-child::after {
      display: inline-block;
      content: "";
      height: 16px;
      width: 16px;
      vertical-align: 0.2em;
      background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' fill='currentColor' class='bi bi-quote' viewBox='0 0 16 16'><path d='M12 12a1 1 0 0 0 1-1V8.558a1 1 0 0 0-1-1h-1.388c0-.351.021-.703.062-1.054.062-.372.166-.703.31-.992.145-.29.331-.517.559-.683.227-.186.516-.279.868-.279V3c-.579 0-1.085.124-1.52.372a3.322 3.322 0 0 0-1.085.992 4.92 4.92 0 0 0-.62 1.458A7.712 7.712 0 0 0 9 7.558V11a1 1 0 0 0 1 1h2Zm-6 0a1 1 0 0 0 1-1V8.558a1 1 0 0 0-1-1H4.612c0-.351.021-.703.062-1.054.062-.372.166-.703.31-.992.145-.29.331-.517.559-.683.227-.186.516-.279.868-.279V3c-.579 0-1.085.124-1.52.372a3.322 3.322 0 0 0-1.085.992 4.92 4.92 0 0 0-.62 1.458A7.712 7.712 0 0 0 3 7.558V11a1 1 0 0 0 1 1h2Z'/></svg>");
      background-repeat: no-repeat;
      background-size: auto;
    }
  }
}
</style>













