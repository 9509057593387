<template>
  <div class="container-fluid about-believe">
    <div class="container py-5">
      <div class="row text-center">
        <div class="col-12 col-md-5 offset-md-1 px-5 pb-md-0">
          <img src="@/assets/elements/our-vision.svg" class="img-fluid" alt="Offer"/>
          <h4 class="fw-bolder">
            Our vision
          </h4>
          <p>
            We believe that providing financial stability will create a brighter future for everyone.
          </p>
        </div>
        <div class="col-12 col-md-5 px-5 pb-md-0">
          <img src="@/assets/elements/our-mission.svg" class="img-fluid" alt="Privacy"/>
          <h4 class="fw-bolder">
            Our mission
          </h4>
          <p>
            To deliver this to every customer through clear, tailored, professional advice, backed up by pioneering technology.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AboutBelieve",
}
</script>
