<template>
  <div class="bmg-policy">
    <Header />
    <Hero col="col-12 col-lg-5">
      <h1 class="fw-bolder">Privacy Policy, Terms &amp; Conditions, Cookie Policy</h1>
    </Hero>
    <div class="container-fluid policy">
      <div class="container pt-3 pb-3">
         <div class="row">
          <div class="col text-center">
            <h2 class="py-3">Mortgages</h2>
          </div>
        </div>
        <div class="row row-eq-height py-3">
          <div class="col-12 col-lg-4 px-5 pb-5 pb-md-0 d-flex flex-column justify-content-between text-center">
            <div>
              <img src="@/assets/elements/brands/believe-finance/logo.svg" class="img-fluid mb-4" alt="Believe Finance"/>
              <p class="small text-center">
                <a href="https://www.believefinance.co.uk/terms" target="_blank">Terms &amp; Conditions</a><br/>
                <a href="https://www.believefinance.co.uk/privacy" target="_blank">Privacy Policy</a><br/>
                <a href="https://www.believefinance.co.uk/cookies" target="_blank">Cookie Policy</a>
              </p>
            </div>
          </div>
          <div class="col-12 col-lg-4 px-5 pb-5 pb-md-0 d-flex flex-column justify-content-between text-center">
            <div>
              <img src="@/assets/elements/brands/believe-loans/logo.svg" class="img-fluid mb-4" alt="Believe Loans"/>
              <p class="small text-center">
                <a href="https://www.believeloans.com/terms" target="_blank">Terms &amp; Conditions</a><br/>
                <a href="https://www.believeloans.com/privacy" target="_blank">Privacy Policy</a><br/>
                <a href="https://www.believeloans.com/cookies" target="_blank">Cookie Policy</a>
              </p>
            </div>
          </div>
          <div class="col-12 col-lg-4 px-5 pb-5 pb-md-0 d-flex flex-column justify-content-between text-center">
            <div>
              <img src="@/assets/elements/brands/believe-specialist-finance/logo.svg" class="img-fluid mb-4" alt="Believe Specialist Finance"/>
              <p class="small text-center">
                <a href="https://www.believespecialistfinance.co.uk/terms" target="_blank">Terms &amp; Conditions</a><br/>
                <a href="https://www.believespecialistfinance.co.uk/privacy" target="_blank">Privacy Policy</a><br/>
                <a href="https://www.believespecialistfinance.co.uk/cookies" target="_blank">Cookie Policy</a>
              </p>
            </div>
          </div>
        </div>

        <div class="row row-eq-height pb-3">
          <div class="col-12 offset-0 col-lg-4 offset-lg-2 px-5 pb-5 pb-md-0 d-flex flex-column justify-content-between text-center">
            <div>
              <h2 class="py-3 text-center">Personal Finance</h2>
              <img src="@/assets/elements/brands/simply-money/logo.svg" class="img-fluid mb-4" alt="Simply Money"/>
              <p class="small text-center">
                <a href="https://www.simplymoney.com/terms-and-conditions" target="_blank">Terms &amp; Conditions</a><br/>
                <a href="https://www.simplymoney.com/privacy-notice" target="_blank">Privacy Policy</a><br/>
                <a href="https://www.simplymoney.com/cookies" target="_blank">Cookie Policy</a>
              </p>
            </div>
          </div>
          <div class="col-12 col-lg-4 px-5 pb-5 pb-md-0 d-flex flex-column justify-content-between text-center">
            <div>
              <h2 class="py-3 text-center">Financial Protection</h2>
              <img src="@/assets/elements/brands/believe-protect/logo.svg" class="img-fluid mb-4" alt="Believe Protect"/>
              <p class="small text-center">
                <a href="https://www.believeprotect.co.uk/terms" target="_blank">Terms &amp; Conditions</a><br/>
                <a href="https://www.believeprotect.co.uk/privacy" target="_blank">Privacy Policy</a><br/>
                <a href="https://www.believeprotect.co.uk/cookies" target="_blank">Cookie Policy</a>
              </p>
            </div>
          </div>
        </div>

        <p>
          Believe Loans, Believe Finance, Believe Protect & Believe Specialist Finance are Appointed Representatives of Believe Advisor Limited (FCA no. 841395) who are authorised and regulated by the Financial Conduct Authority.
        </p>
        <p>
          Simply Money is a trading style of Click Loans Ltd ( 796023) who are authorised and regulated by the Financial Conduct Authority.
        </p>
        <p>
          All the Believe firms named above and Simply Money are part of the Believe Money Group.
        </p>
        <p>
          As joint controllers of your data, Believe Money Group firms comply with the Data Protection Laws in the UK and the UK General Data Protection Regulation.
        </p>
        <p>
          We are committed to protecting your privacy. The following notices provide information in relation to how the Believe Money Group Firms use your personal data. We recommend you read these together with our Terms and Conditions.
        </p>

      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from '../Sections/Scaffold/Header.vue'
import Footer from '../Sections/Scaffold/Footer.vue'
import Hero from "../Sections/Hero.vue";

export default {
  name: 'Policy',
  components: {
    Hero,
    Header,
    Footer
  }
}
</script>

<style scoped>
.bmg-policy .policy a,
.bmg-policy .policy a:link,
.bmg-policy .policy a:active,
.bmg-policy .policy a:focus {
  color: black;
}
</style>
