<template>
  <div class="container-fluid click-tech">
    <div class="container py-5">
      <div class="row align-items-center">
        <div class="col-12 col-lg-6 order-last order-lg-first">
          <img src="@/assets/elements/bespoke-technology.png" class="img-fluid mx-auto d-flex py-0 py-md-4 pe-5" alt="Click Tech"/>
        </div>
        <div class="col-12 col-lg-6 order-first order-lg-last">
          <h2 class="fw-bolder">Bespoke Technology</h2>
          <p>Believe Money Group makes it easy to borrow and simple to lend. We’re
            the UK’s leading hybrid broker, using bespoke technology to make
            powerful connections – between our expert teams, the UK’s top lenders,
            and customers who want great deals and super-smooth service.</p>
          <router-link to="/technology" class="btn btn-bmg icon-right d-flex d-md-inline-block">Our Technology <i class="bi bi-caret-right-fill"></i></router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ClickTech"
}
</script>

<style scoped>

</style>