<template>
  <div class="container-fluid bg-tertiary finance-issued">
    <div class="container py-5 text-center">
      <h4 class="fw-bolder fs-3">Believe in numbers...</h4>
      <p class="text-danger display-3 fw-bolder py-3 finance-issued__amount">£ <span>750,000,000</span>
      <h4>Finance issued across Believe Money Group</h4>
      <hr class="my-5"/>
      <div class="row">
        <div class="col-12 col-md-4 pb-5 pb-md-0">
          <img src="@/assets/elements/stat-completed.svg" class="img-fluid" alt="Loans and mortgages completed"/>
          <h5 class="pt-5 pb-3">Loans and mortgages<br/>completed</h5>
        </div>
        <div class="col-12 col-md-4 pb-5 pb-md-0">
          <img src="@/assets/elements/stat-employees.svg" class="img-fluid" alt="Employees... and growing!"/>
          <h5 class="pt-5 pb-3">Employees...<br/>and growing!</h5>
        </div>
        <div class="col-12 col-md-4">
          <img src="@/assets/elements/stat-offices.svg" class="img-fluid" alt="Offices across the north"/>
          <h5 class="pt-5 pb-3">Offices across<br/>the north</h5>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Finance-Issued",
}
</script>

<style scoped lang="scss">
@import 'bootstrap';
.finance-issued__amount {
  span {
    letter-spacing: 10px;
    @include media-breakpoint-down(lg) {
      letter-spacing: 1px;
    }
  }
}
</style>













