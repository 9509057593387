<template>
  <div class="apply-for-position">
    <div class="row">
      <div class="col-12 col-md-6">
        <div class="mb-3" v-if="potentialLocations.length">
          <label for="location" class="form-label">Position Location</label>
          <select
              class="form-select"
              id="location"
              v-model="parameters.location.value"
              required
              @focus="parameters.location.touch = true"
              @change="isValid('location')"
              :class="{ 'is-invalid': parameters.location.touch && parameters.location.error }"
              :disabled="submission.working || (locationFilter.length > 0 && potentialLocations.length === 1)"
          >
            <option :value="null">Select a location</option>
            <option v-for="location in potentialLocations" :key="location.value" :value="location.value">{{ location.label }}</option>
          </select>
        </div>
        <div class="mb-3">
          <label for="name" class="form-label">Name</label>
          <input
              type="text"
              class="form-control"
              id="name"
              placeholder="Your full name"
              v-model="parameters.name.value"
              required
              @focus="parameters.name.touch = true"
              @input="isValid('name')"
              :class="{ 'is-invalid': parameters.name.touch && parameters.name.error }"
              :disabled="submission.working"
          />
        </div>
        <div class="mb-3">
          <label for="address" class="form-label">Address</label>
          <textarea
              class="form-control"
              id="address"
              rows="5"
              placeholder="Your address"
              v-model="parameters.address.value"
              required
              @focus="parameters.address.touch = true"
              @input="isValid('address')"
              :class="{ 'is-invalid': parameters.address.touch && parameters.address.error }"
              :disabled="submission.working"
            />
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="mb-3">
          <label for="email" class="form-label">Email Address</label>
          <input
              type="email"
              class="form-control"
              id="email"
              placeholder="Your email address"
              v-model="parameters.email.value"
              required
              @focus="parameters.email.touch = true"
              @input="isValid('email')"
              :class="{ 'is-invalid': parameters.email.touch && parameters.email.error }"
              :disabled="submission.working"
          />
        </div>
        <div class="mb-3">
          <label for="phone" class="form-label">Phone Number</label>
          <input
              type="tel"
              class="form-control"
              id="phone"
              placeholder="Your phone number"
              v-model="parameters.phone.value"
              required
              @focus="parameters.phone.touch = true"
              @input="isValid('phone')"
              :class="{ 'is-invalid': parameters.phone.touch && parameters.phone.error }"
              :disabled="submission.working"
          />
        </div>
        <div class="mb-3">
          <label for="cv" class="form-label">Upload your CV</label>
          <input
              class="form-control"
              type="file"
              id="cv"
              @change="onFileUpload"
              tabindex="-1"
              required
              @focus="parameters.cv.touch = true"
              @input="isValid('cv')"
              :class="{ 'is-invalid': parameters.cv.touch && parameters.cv.error }"
              :disabled="submission.working"
              ref="fileUpload"
          />
          <div class="invalid-feedback" v-if="parameters.cv.touch && parameters.cv.value && parameters.cv.value.size > 25000000">
            Please upload a file smaller than 25mb.
          </div>
          <small class="form-text text-muted">Max file size 25mb</small>
        </div>
      </div>
    </div>
    <div v-if="submission.error" class="alert alert-danger" role="alert">
      There was an error submitting your application. Please try again later.
    </div>
    <div v-if="submission.success" class="alert alert-success" role="alert">
      Your application has been submitted successfully and we will be in touch soon.
    </div>
    <button :disabled="submission.working || submission.success" class="btn btn-primary" @click="submit">Submit</button>
  </div>
</template>

<script>
import {locations, recruitmentApi} from "./job-positions";

export default {
  name: "ApplyForPosition",
  props: {
    role: {
      type: Object,
      required: false
    },
    locationFilter: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  mounted() {
    this.reset();
  },
  data() {
    return {
      parameters: {
        location: {
          value: null,
          touch: false,
          error: false
        },
        name: {
          value: null,
          touch: false,
          error: false
        },
        address: {
          value: null,
          touch: false,
          error: false
        },
        email: {
          value: null,
          touch: false,
          error: false
        },
        phone: {
          value: null,
          touch: false,
          error: false
        },
        cv: {
          value: null,
          touch: false,
          error: false
        },
      },
      cvData: null,
      submission: {
        success: false,
        error: false,
        working: false
      }
    }
  },
  computed: {
    potentialLocations() {
      const locs = Object.keys(locations).map(key => {
        return {
          value: key,
          label: locations[key]
        }
      });
      if (this.locationFilter.length) {
        return locs.filter(loc => this.locationFilter.includes(loc.label));
      }
      return locs;
    },
    formIsValid() {
      for (const field in this.parameters) {
        this.isValid(field);
      }
      // check if any fields have errors
      for (const field in this.parameters) {
        if (this.parameters[field].error) {
          return false
        }
      }
      return true
    }
  },
  methods: {
    isValid(field) {
      if (field === "location") {
        if (this.potentialLocations.length > 0) {
          this.parameters[field].error = !this.parameters[field].value || this.parameters[field].value.length === 0;
        }
        else {
          this.parameters[field].error = false;
        }
      }
      if (field === "cv") {
        // size is less than 25mb
        this.parameters[field].error = !this.parameters[field].value || this.parameters[field].value.size > 25000000;
      }
      else if (field === "email") {
        this.parameters[field].error = !this.parameters[field].value || this.parameters[field].value.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/) === null;
      }
      else if (field === "phone") {
        // any uk landline or mobile number
        this.parameters[field].error = !this.parameters[field].value || this.parameters[field].value.match(/^((\+44\s?|0)(\d{3}|\d{4})\s?\d{3}\s?\d{3})|((\+44\s?|0)7\d{3}\s?\d{6})$/) === null;
      }
      else {
        this.parameters[field].error = !this.parameters[field].value || this.parameters[field].value.length === 0;
      }
    },
    onFileUpload(event) {
      this.parameters.cv.touch = true;
      this.parameters.cv.value = event.target.files[0];
      this.isValid("cv");
      console.log(this.parameters.cv);
    },
    submit() {
      if (!this.formIsValid) {
        // touch all fields
        for (const field in this.parameters) {
          this.parameters[field].touch = true;
        }
        return
      }
      const reader = new FileReader();
      reader.onload = () => {
        this.cvData = reader.result.split(",")[1];
        this.process();
      };
      reader.readAsDataURL(this.parameters.cv.value);
    },
    process() {
      this.submission.working = true;
      let payload = {
        url: window.location.href,
        role: this.role ? this.role.title : null,
        department: this.role ? this.role.more.department : null,
        location: locations[this.parameters.location.value],
        name: this.parameters.name.value,
        address: this.parameters.address.value,
        email: this.parameters.email.value,
        phone: this.parameters.phone.value,
        cv: [{
          content: this.cvData,
          filename: this.parameters.cv.value.name,
          type: this.parameters.cv.value.type
        }]
      };
      fetch(recruitmentApi, {
        method: "POST",
        body: JSON.stringify(payload),
      })
        .then(() => {
          this.submission.success = true;
          this.submission.working = false;
          this.reset();
          setTimeout(() => {
            this.submission.success = false;
          }, 10000);
        })
        .catch(error => {
          console.error(error);
          this.submission.success = true;
          this.submission.working = false;
        });
    },
    reset() {
      this.submission.error = false;
      this.submission.working = false;
      this.$refs.fileUpload.value = null;
      for (const field in this.parameters) {
        this.parameters[field].value = null;
        this.parameters[field].touch = false;
      }
      if (this.locationFilter.length && this.potentialLocations.length === 1) {
        this.parameters.location.value = this.potentialLocations[0].value
      }
    }
  }
}
</script>

<style scoped>

</style>